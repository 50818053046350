var App = require("~/js/App");

module.exports = {

	//contenful access
	space: 'cqva3bpxaiuu',
	token: 'kDg_zjGzJVs0n1QbgIJWDtHJRrXEpAQ7cz-eZ5JSHiI',

	//api
	api: 'https://cdn.contentful.com/spaces/cqva3bpxaiuu',


    getLocales: function ( type, field, value ) {
		return this.api +"/locales?access_token="+ this.token;
	},

    getEntries: function ( type, field, value, include ) {
    	var contentType = ( type ? "&content_type=" + type : "" );
    	var fields = ( field ? "&fields." + field + "=" + value : "" );
    	var locale = "&locale=" + App.LANGUAGE;
    	var include = ( include ? "&include=" + include : "" );
    	//
		return this.api +"/entries?access_token="+ this.token + contentType + fields + locale + include;
	},

    getEntry: function ( entryId ) {
    	var locale = "&locale=" + App.LANGUAGE;    	
		return this.api +"/entries/" + entryId + "?access_token="+ this.token + locale;
   	},	

	getAssets: function () {
		return this.api +"/assets?access_token="+ this.token;
	},

	getInclude: function( res, id ) {
		return _.filter( res, function( item ) {
    		return item.sys.id === id;
		});
	}

};