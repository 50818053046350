var template = require('~/js/template/layout/cookieTemplate.html'),
    App = require("~/js/App"),
    Utils = require('~/js/Utils');

module.exports = Backbone.View.extend({ 

    id: 'cookie',

    className: 'cookie',

	cookieConset: 'cookieConset',

    events: {
    	"click .bar .actions .set":  	"onActionSet",
    	"click .bar .actions .ok":  	"onActionOk",
        "click .bar .decline a":  		"onDecline",
        "click .exit-btn":  			"onSetExit",
        "click .accordion-list .accordion": "onAccordionClick",
        "click input[type=checkbox]" : "onCheckboxClick",
        "click .confirm-btn" :          "onSetConfirmClick"
        
    },   

    initialize: function () {
        var bools = Utils.decryptBools( Utils.getCookie( this.cookieConset) );  
        //
        if ( bools.length === 0 ) {
            this.show();
        } else {
            console.log("hodnoty", bools, ( bools[1] ? 'granted' : 'denied' ), ( bools[2] ? 'granted' : 'denied' ), ( bools[3] ? 'granted' : 'denied' ));           
            gtag('consent', 'update', {
                'analytics_storage': ( bools[1] ? 'granted' : 'denied' ),
                'personalization_storage': ( bools[2] ? 'granted' : 'denied' ),
                'ad_storage': ( bools[3] ? 'granted' : 'denied' )
            }); 
        }
    },

    render: function() {
        var bools = Utils.decryptBools( Utils.getCookie( this.cookieConset) );  
        //
    	this.el.innerHTML = template( { bools: bools, translate: App.translateCollection, Utils } );    	
    	//
        return this;
    },


    show: function() {
        this.render();        
        Utils.addClass( this.el, 'show' );
    },

    hide: function() {
        Utils.removeClass( this.el, 'show' );

    },

    onActionSet: function( evt ) {
    	Utils.addClass( this.el.querySelector( '.overlay-set' ), 'show' );
    },

    onAccordionClick: function( evt ) {
        evt.currentTarget.classList.toggle("active");

        var panel = evt.currentTarget.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        } 
    },

    onCheckboxClick: function(evt) {
        var index = Number( evt.currentTarget.id );
        var name = evt.currentTarget.name;        
        var checked = evt.currentTarget.checked;
        var bools = Utils.decryptBools( Utils.getCookie( this.cookieConset) );
        //
        if ( bools.length === 0 )
            bools = [true, false, false, false];
        //
        console.log( { [name] : ( checked ? 'granted' : 'denied' ) } );
        bools[ index ] = checked;
        console.log( bools, index, checked );
        //        
        gtag('consent', 'update', { [name] : ( checked ? 'granted' : 'denied' ) }); 
        dataLayer.push({'event': 'consent-update'});
        Utils.setCookie( this.cookieConset, Utils.encryptBools( bools ), 365);
    },

    onSetConfirmClick: function( evt ) {
        Utils.removeClass( this.el.querySelector( '.overlay-set' ), 'show' );
        this.hide();
    },

    onSetExit: function(evt) {
		Utils.removeClass( this.el.querySelector( '.overlay-set' ), 'show' );
    },

    onActionOk: function( evt ) {
	    //gtm
	    gtag('consent', 'update', { 'ad_storage': 'granted', 'analytics_storage': 'granted', 'personalization_storage': 'granted' });  
	    dataLayer.push({'event': 'consent-update'});
    	Utils.setCookie( this.cookieConset, Utils.encryptBools([true, true, true, true]), 365);
    	//
    	this.hide();
    },    

    onDecline: function( evt ) {
    	evt.preventDefault();
    	//
	    gtag('consent', 'update', { 'ad_storage': 'denied', 'analytics_storage': 'denied', 'personalization_storage': 'denied' });  
	    dataLayer.push({'event': 'consent-update'});
    	Utils.setCookie( this.cookieConset, Utils.encryptBools([true, false, false, false]), 365);
		//
    	this.hide();
    },      

    dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }

});
