var Model = require('../model/TranslateModel'),
	App = require("~/js/App"),
	Contentful = require('~/js/Contentful'),	
	Renderer = require('@contentful/rich-text-html-renderer');

module.exports = Backbone.Collection.extend({
        
    model: Model,

	parse : function( res ) {
		var data = [];
		//
 		res.items.forEach(function( res, index) {
			data.push( new Model({
		    	id:  res.fields.id,
		    	shortText: res.fields.text,
		    	longText: res.fields.longText ? Renderer.documentToHtmlString(res.fields.longText) : ''
        	}));

		});
		//
		return data;
    },
    //get translation text
    getText: function( id, prop){
      return _.filter( App.translateCollection.models, function( item ) {
          return item.get('id') === id;
      })[0].get(prop);
    },
});