import { CubeMap } from './nested/CubeMap';
import { PanoModels } from './nested/PanoModels';
import App from '~/js/App';
import Utils from '~/js/Utils';

class ProductInstance3DView {

	constructor ( pc ) {
		this.name = 'ProductInstance3DView';
	    this.pc = pc;
	    //
		this.cube = new CubeMap( this.pc.app );
	    this.panoModels = new PanoModels( this.pc.app );	 
	}

	init( model ) {
		this.model = model;	
		//
		this.pc.cameraOffset.setLocalPosition( model.get('cameraPosition') );
		this.pc.cameraRotate.script.lookCamera.setCameraRotation( model.get('cameraRotation').x, model.get('cameraRotation').y );
		
		//distance between camera and objectsd
		var distance = this.pc.cameraRotate.getPosition().sub( model.get('position') ).length();
      	this.lookYaw = this.pc.cameraRotate.script.lookCamera.ey;
      	this.lookPitch = this.pc.cameraRotate.script.lookCamera.ex;

      	//get the temp pivot position from distance of object and forward direction
		var fwdPosition = new pc.Vec3();
		fwdPosition.add2( this.pc.cameraRotate.getPosition(), this.pc.cameraRotate.forward.scale( 0.1 ) ); 

      	//reset and turn off lookatrotate
      	this.pc.cameraRotate.setLocalEulerAngles( 0, 0, 0 );
      	this.pc.cameraRotate.script.lookCamera.enabled = false;	      	
   
        this.pc.camera.script.orbitCamera.enabled = true;	      	
   		this.pc.camera.script.orbitCamera.fov= model.get('fov');        
      	this.pc.camera.script.orbitCamera._pivotPoint = fwdPosition;
      	this.pc.camera.script.orbitCamera._targetPivotPoint = model.get('position');

		this.pc.camera.script.orbitCamera._distance = distance;	   	
      	this.pc.camera.script.orbitCamera.distance = model.get('distance');	      	

      	this.pc.camera.script.orbitCamera._yaw = this.lookYaw ;
      	this.pc.camera.script.orbitCamera._pitch = this.lookPitch;
		this.pc.camera.script.orbitCamera.yaw = model.get('yawPitch').x;
		this.pc.camera.script.orbitCamera.pitch = model.get('yawPitch').y;
		//
		var changeCubemap = this.model.get('productInstance')[0].products[ this.getProductIndex( this.model.get('productInstance')[0].products ) ].changeCubemap;
		//
		this.cube.load( this.model.get('sphereLink'), changeCubemap ).then(( cubemap ) => {
			//
			this.pc.camera.script.posteffectExample.enabled = true;
			this.pc.camera.script.posteffectExample.setCubeFrom( cubemap );
			this.pc.camera.script.posteffectExample.setCubeTo( cubemap );
			//
			this.panoModels.create( model.get('productInstance') );		 
			App.dispose.view.preDispose( this.name );   		
		});
	}
	//view methods
	setModelBySlug( modelInstanceId, slug ) {
		//switch cube map
		var changeCubemap = this.model.get('productInstance')[0].products[ this.getProductIndex( this.model.get('productInstance')[0].products ) ].changeCubemap;
		//
		if ( this.cube.isNeedUpdate( this.model.get('sphereLink'), changeCubemap ) ) {
			this.cube.updateCube( this.model.get('sphereLink'), changeCubemap ).then(( cubemap ) => {
				this.pc.camera.script.posteffectExample.setCubeFrom( cubemap );
				this.pc.camera.script.posteffectExample.setCubeTo( cubemap );
			});
		}
		//
		var currentModel = this.getCurrentModel( modelInstanceId );
		//switch old model with new one
		currentModel.enabled = false;
		this.panoModels.root.findByName( modelInstanceId ).findByName(  slug ).enabled = true;
	}

	playAnimationClip( modelInstanceId, animationClip ) {
		var currentModel = this.getCurrentModel( modelInstanceId );
		currentModel.script.loadModel.switchToClipByName( animationClip );
	}

	changeMaterial( modelInstanceId, target, val ) {
		var currentModel = this.getCurrentModel( modelInstanceId );
		currentModel.script.material.change( target, val );
	}
	playVideo( modelInstanceId, target, val ) {
		var currentModel = this.getCurrentModel( modelInstanceId );
		currentModel.script.video.play( target, val );
	}
	//tools
	getCurrentModel( modelInstanceId ) {
		var currentModel = this.panoModels.root.findByName( modelInstanceId ).children.filter( ( child ) => {
			return child.enabled === true ;
		})[0];
		return currentModel;
	}

    getProductIndex( array ) {
        //get current product index in model array
        var productSlug = Utils.getURLpart( window.location.pathname, 4);        
        this.currIndex = array.findIndex( ( item ) => {
            return item.slug === productSlug;
        });
        return this.currIndex;
    }

	//dispose methods
	preDispose( id ) {
		console.log('__product instance predispose', id);
		this.dispose( id );
	}

	dispose( id ) {
		console.log('__product instance dispose', id);

     	this.pc.cameraRotate.script.lookCamera.ey = this.pc.camera.script.orbitCamera.yaw;
      	this.pc.cameraRotate.script.lookCamera.ex = this.pc.camera.script.orbitCamera.pitch;
     	this.pc.cameraRotate.script.lookCamera.targetEy = this.lookYaw;
      	this.pc.cameraRotate.script.lookCamera.targetEx = this.lookPitch;

        this.pc.camera.script.orbitCamera.enabled = false;
		//tween entities to defaults	    
		this.pc.camera.setLocalPosition(0,0,0);
		this.pc.camera.setLocalEulerAngles( 0, 0, 0 );	

		this.pc.cameraRotate.script.lookCamera.enabled = true;
		//if id is undefined dispose is called from any 2d views, it means complete dispose 3D
		if ( typeof id === 'undefined' || id === 'Product3DView' ) this.disposeAll();
	}

	disposeAll() {
		//complete dispose 3D
		console.log('__product instance complete dispose!!!');	
		this.cube.dispose();
		this.panoModels.dispose('soloView'); //except soloView	
	}
  };

export default ProductInstance3DView;