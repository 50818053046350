import { CubeMap } from './nested/CubeMap';
import { PanoDirections } from './nested/PanoDirections';
import { PanoModels } from './nested/PanoModels';
import { PanoPPP } from './nested/PanoPPP';
import App from '~/js/App';
import Utils from '~/js/Utils';

class Pano3DView {

	constructor ( pc ) {
		this.name = 'Pano3DView';
	    this.pc = pc;
		//directions and modelsa
		this.cube = new CubeMap( this.pc.app );		
		this.panoDirections = new PanoDirections( this.pc.app );
		this.panoModels = new PanoModels( this.pc.app );	    
		this.PanoPPP = new PanoPPP( this.pc.app );	
	}

	init( model ) {
		App.loader.show();
		this.model = model;		
		//
		//this.pc.cameraRotate.script.lookCamera.enabled = true;
		//set position of camera
		this.pc.cameraOffset.setLocalPosition( Utils.stringToVec3( this.model.get('cameraPosition') ) );
		//
		this.cube.load( this.model.get('sphereLink') ).then(( cubemap ) => {
			this.panoModels.create( this.model.get('productsInPano'), true ).then(() => { 

				this.pc.cameraRotate.script.lookCamera.setCameraRotation( this.model.get('cameraRotation').x, this.model.get('cameraRotation').y );
				this.pc.cameraRotate.script.lookCamera.setCameraFov( this.model.get('cameraFov') );				
				this.pc.camera.script.posteffectExample.enabled = true;
				this.pc.camera.script.posteffectExample.setCubeFrom( cubemap );
				this.pc.camera.script.posteffectExample.setCubeTo( cubemap );
				//
				this.setDirections( true );
				this.PanoPPP.create( this.model.get('pppProducts') );
			});	    		
		});
	}

	update() {
		this.panoDirections.dispose();  //pokusim se presunout na tridu
		this.PanoPPP.dispose();
		//
		let cubemapTo = this.pc.app.assets._assets.find(o => o.name === this.model.get('sphereLink') );
		if ( cubemapTo ) {
			cubemapTo = cubemapTo.resource;
			//
			this.pc.camera.script.posteffectExample.setCubeTo( cubemapTo );	    			
			//
			this.pc.camera.script.posteffectExample.doTransition( this.pc.cameraOffset, Utils.stringToVec3( this.model.get('cameraPosition') ) ).then( () => {
				this.setDirections();
				this.PanoPPP.create( this.model.get('pppProducts') );
			});
		} else {
			//pano not exist in assets, must be loaded
			this.cube.load( this.model.get('sphereLink') ).then(( cubemap ) => {
				let cubemapTo = this.pc.app.assets._assets.find(o => o.name === this.model.get('sphereLink') );
				cubemapTo = cubemapTo.resource;
				//
				this.pc.camera.script.posteffectExample.setCubeTo( cubemapTo );	    			
				//
				this.pc.camera.script.posteffectExample.doTransition( this.pc.cameraOffset, Utils.stringToVec3( this.model.get('cameraPosition') ) ).then( () => {
					this.setDirections();
					this.PanoPPP.create( this.model.get('pppProducts') );
				});
			});
		}

		

	}

	setDirections( isInit ) {
		this.panoDirections.create( this.model.get('cameraPosition'), this.model.get('directions') ).then(() => { 

			async function asyncForEach( that, array ) {
				for (const dir of array) {
					await that.cube.load( dir.sphereLink );
					await that.panoModels.create( dir.productsInPano, false );
					//
					that.pc.app.fire('panoLoaded:' + dir.sphereLink );
				}
				//prepare current cubemap with neighbors cubemaps
				var cubesToLoad = [ that.model.get('sphereLink') ].concat( that.model.get('directions').map( ( dir ) => { return dir.sphereLink; }) );
				that.cube.cleanUnused( cubesToLoad );		
				//
				if ( isInit ) {
					App.loader.hide();
					App.dispose.view.preDispose( that.name );
				}
			}
			this.panoModels.create( this.model.get('productsInPano'), true ).then(() => { 
				asyncForEach( this, this.model.get('directions') );
			});
		});
	}

	preDispose( id ) {
		console.log('__pano predispose',  id);
		this.dispose( id );
	}

	dispose( id ) {
		console.log('__pano dispose', id);	
		if ( this.panoDirections ) {
	    	this.panoDirections.dispose();
	    	this.panoDirections = null;
		}
		if ( this.PanoPPP ) {
	    	this.PanoPPP.dispose();
	    	this.PanoPPP = null;
		}
		
		//I will need only one cubemaps, other for directions disposed

		this.cube.cleanUnused( [ this.model.get('sphereLink') ] );
		//if id is undefined dispose is called from any 2d views, it means complete dispose 3D
		if ( typeof id === 'undefined' || id === 'Product3DView' ) this.disposeAll();
	}

	disposeAll() {
		//complete dispose 3D
		console.log('__pano complete dispose!!!');	
		this.cube.dispose();
		this.panoModels.dispose('soloView'); //except soloView
	}
  };

export default Pano3DView;