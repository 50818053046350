var Contentful = require('~/js/Contentful'),
    App = require("~/js/App"),
    Utils = require('~/js/Utils'),
    Renderer = require('@contentful/rich-text-html-renderer');

module.exports = Backbone.Model.extend({
    defaults: {
        title: '',
        position: '',
        sphereLink: '',
        cameraRotation: '0,0',        
        cameraPosition: '',
        productInstance: []
	},

    parseDocuments: function( data ) {
        if ( !data ) return null;
        var documents = [];
        data.split('\n').forEach( ( document ) => {
            var temp = document.split(/:(.+)/); //split only first occurence
            if ( temp.length ) {
                var ext = Utils.getUrlExtension( temp[1] );
                documents.push( { title : temp[0], ext:ext, url: temp[1].trim()  } );
            }
        });
        return documents;
    },

	parse : function( res ) {
		//console.log( 'product res', res );
		//
        var entry = res.includes ? res.includes.Entry : null;   
        var productInstance = [];
        var products = [];
        //
        res.items.forEach( ( product ) => {
            var obj = {};
            obj.id = product.sys.id;

            obj.slug = product.fields.slug;
            obj.title = product.fields.title;
            obj.desc = product.fields.desc;
            obj.modelLink = product.fields.modelLink;
            obj.documents = this.parseDocuments( product.fields.documents );
            obj.desc = Renderer.documentToHtmlString( product.fields.desc );
            if ( product.fields.hasOwnProperty('state') ){
                var states = [];
                product.fields.state.forEach( ( state ) => {
                    states.push( Contentful.getInclude( entry, state.sys.id )[0].fields  );   
                });
                obj.state = states;
            }

            if ( product.fields.hasOwnProperty('flowInfo') ){
                var flowInfos = [];
                product.fields.flowInfo.forEach( ( flowInfo ) => {
                    flowInfos.push( Contentful.getInclude( entry, flowInfo.sys.id )[0].fields  );   
                });
                obj.flowInfo = flowInfos;
            }
            //materials
            obj.materialTarget = product.fields.materialTarget;
            if ( product.fields.hasOwnProperty('material') ){
                var materials = [];
                product.fields.material.forEach( ( material ) => {
                    var fields = Contentful.getInclude( entry, material.sys.id )[0].fields;
                    fields.id = material.sys.id;
                    fields.pictureModel = fields.picture ? App.assetsCollection.where({ id: fields.picture.sys.id })[0] : null;
                    fields.diffuseBmp = fields.diffuseBmp ? App.assetsCollection.where({ id: fields.diffuseBmp.sys.id })[0] : null;
                    materials.push( fields  );   
                });
                obj.material = materials;
            }
            //videos
            obj.videoTarget = product.fields.videoTarget;
            if ( product.fields.hasOwnProperty('video') ){
                var videos = [];
                product.fields.video.forEach( ( video ) => {
                    var fields = Contentful.getInclude( entry, video.sys.id )[0].fields;
                    fields.id = video.sys.id;
                    fields.pictureModel = fields.picture ? App.assetsCollection.where({ id: fields.picture.sys.id })[0] : null;
                    fields.videoFile = fields.videoFile;
                    fields.autoPlay = fields.autoPlay;
                    videos.push( fields );   
                });
                obj.video = videos;
            }
            //
            products.push( obj );
        });
        productInstance.push({ id: 'soloView', position: '0,0,0', scale: Utils.stringToVec3('1,1,1'), interactive: 0, disableHiding: 1, products: products });
        //
        this.set('productInstance', productInstance, {silent: false} );
    }


});