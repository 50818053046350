var Utils = require('~/js/Utils');
import protoScript from "~/js/lib/ProtoScript";

var Direction = function(){
    this.name = 'direction';
    //
    this.attributes = {
        'id'   : { type: "string", default:"" },      
        'sphereLink': { type: "string", default:"" }, //for enabling identify
        'slug': { type: "string", default:"" },
        'correction': { type: "vec3" },
        'correctionWithTransition': { type: "boolean", default: false },
    };
};

Direction.prototype.initialize = function() {
    this.color = '#005598ff';
    //this.t = 0;
    //            
    this.entity.rotateLocal(55, 180, 0);
    //
    this.entity.addComponent('element', {
        type: 'image',
        preset: "Center",
        anchor: [0.5,1,0.5,1],
        pivot: [0.5, 1],
        width: 0.15,
        height: 0.15,
        //color: new pc.Color().fromString( this.color ),
        textureAsset: this.app.assets.find("arrow1.png"),
        opacity: 1
    });
     this.entity.element.layers = [ pc.LAYERID_WORLD ];    
    //
     this.entity.addComponent('button', {
        active: true,
        transitionMode: pc.BUTTON_TRANSITION_MODE_TINT,
        imageEntity: this.entity,
        hoverTint: new pc.Color().fromString( this.color ),
        pressedTint: new pc.Color(0.5, 0.5, 0.5),
        inactiveTint: new pc.Color().fromString( this.color ),
        fadeDuration: 1
    });
     //
    this.entity.element.on('click', this.onDirectionDown, this); 
    this.entity.element.on('mouseenter', this.onEntityEnter, this);
    this.entity.element.on('mouseleave', this.onEntityLeave, this);

    this.on('destroy', this.onDestroy, this);    
    this.app.on('panoLoaded:' + this.sphereLink, this.onPanoLoaded, this );
    //
    this.camera = this.app.root.findByName('camera');
    this.alpha = -1.0;
    this.inertiaFactor = 0.03;
    this.targetX = 0;
    this.targetY = 0;
};
//handlers
Direction.prototype.onPanoLoaded = function ( evt ) {
    this.entity.element.useInput = true; 
};
Direction.prototype.onEntityEnter = function ( evt ) {
    document.body.style.cursor = "pointer";
};
Direction.prototype.onEntityLeave = function ( evt ) {
    document.body.style.cursor = "auto";
};
Direction.prototype.onDirectionDown = function ( evt ) {
    if ( this.correction )
        this.camera.script.posteffectExample.setLookCamera( this.correction, this.correctionWithTransition );
	Backbone.history.navigate( Utils.changeURLpart( window.location.pathname, 2, this.slug) , true );
};
Direction.prototype.onDestroy = function () {
    this.entity.element.off('click', this.onDirectionDown, this);  
    this.app.off('panoLoaded:' + this.sphereLink, this.onPanoLoaded, this );
    this.off('destroy', this.onDestroy, this);    
};

Direction.prototype.update = function (dt) {
	//var s = Math.sin(this.t * 2) + 2;
    //this.t += dt;
    
    //this.entity.setLocalPosition(0, 0, 0);
    var t = this.inertiaFactor === 0 ? 1 : Math.min(dt / this.inertiaFactor, 1);
    var element = document.getElementById( this.id );
    //
    if ( element ) {
        this.alpha += ( Math.min( 1.0, this.entity.forward.dot( this.camera.forward ) * 3 ) - this.alpha ) * 0.1;
        var point = this.camera.camera.worldToScreen( this.entity.getPosition() );
        this.targetX = pc.math.lerp(this.targetX, point.x, t);
        this.targetY = pc.math.lerp(this.targetY, point.y, t);
        //
        element.style.top = ( this.targetY - 20) + "px"; //(this.popupPosition.y - this.popup.offsetHeight - 70) + "px";
        element.style.left = this.targetX - element.offsetWidth / 2 + "px";
        element.style.opacity = this.alpha;
        //
    } 
};

export default protoScript( Direction );
