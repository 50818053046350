var App = require("~/js/App"),
	Utils = require('~/js/Utils');

var LookCamera = require('./pc.script/LookCamera').default,
	PostEffectExample = require('./pc.script/PostEffectExample').default;

var KeyboardInput = require('./pc.script/vendors/KeyboardInput').default;
var MouseInput = require('./pc.script/vendors/MouseInput').default;
var TouchInput = require('./pc.script/vendors/TouchInput').default;
var OrbitCamera = require('./pc.script/vendors/OrbitCamera').default;

var Tween = require('./pc.script/vendors/Tween').default;
var PostEffect = require('./pc.script/PostEffect');
var PostEffectFxAA = require('./pc.script/vendors/PostEffect-fxaa');

var vs = require('./shaders/postEffect.vs').default,
	fs = require('./shaders/postEffect.fs').default;

import { DebugPC } from './DebugPC';

var Playcanvas = function( readyCall ){
    this.name = 'playcanvas';
    this.readyCall = readyCall;
    //
    this.canvas = document.getElementById("application-canvas");
	this.app = new pc.Application( this.canvas, {
		mouse: new pc.Mouse( document.body ),
		keyboard: new pc.Keyboard(window),
		touch: new pc.TouchDevice(document.body),
		elementInput: new pc.ElementInput( this.canvas ),
		graphicsDeviceOptions: {
            //alpha: false //prevent white leak with opacity map i used
        }
	});
	
	//if (pc.app.graphicsDevice.webgl2 === false) // do something

	var debugPC = new DebugPC( this.app );

   	this.setupWASM();
};

//*************************************************HACK PC -> add tags(extras) and bumpines hack
//46372 var createNode = function (nodeData, nodeIndex) {
        //add extrass propertis to graphnode
        //if (nodeData.hasOwnProperty('extras')) {
        //    entity.extras = nodeData.extras;
        //}


//46159 if (materialData.hasOwnProperty('normalTexture')) {
// 			....
            //custom hack, because not method to set bump scale
//            material.bumpiness = pbrData.metallicFactor;        
//}

//46206
// case 'BLEND':
// material.blendType = pc.BLEND_PREMULTIPLIED; //pc.BLEND_NORMAL;

Playcanvas.prototype = {

	    setupWASM: function () {
			Utils.loadScript( App.ASSETS + 'js/wasm-loader.js' ).then(() => {
				pc.basisDownload(
					App.ASSETS + 'js/basis.wasm.js', 
					App.ASSETS + 'js/basis.wasm.wasm',
					App.ASSETS + 'js/basis.js',
					() => {
						if ( wasmSupported() ) {
							loadWasmModuleAsync('DracoDecoderModule', App.ASSETS + 'js/draco.wasm.js', App.ASSETS + 'js/draco.wasm.wasm', () => {
								this.setup3D();
							});
						} else {
							loadWasmModuleAsync('DracoDecoderModule', App.ASSETS + 'js/draco.js', '',  () => {
								this.setup3D();
							});
						}
					}
				);
            });
	    },

	    setup3D: function () {
	    	//setup tweener
			var tween = new Tween();
			//
	        this.resize();
	        window.addEventListener('resize', this.resize.bind(this), false);
	        window.addEventListener('orientationchange', this.resize.bind(this), false);

		    // use device pixel ratio
		    this.app.graphicsDevice.maxPixelRatio = window.devicePixelRatio; //most important for mobile retina aliasing quality
	        this.app.start();        
	        this.app.setCanvasFillMode( pc.FILLMODE_FILL_WINDOW );
	        this.app.setCanvasResolution( pc.RESOLUTION_AUTO );


	        this.app.root.name = 'RootNode';
	        
			//this.app.mouse.disableContextMenu();

	        this.setHDRIMap();

	        // Create an Entity with a point light component and a sphere model component.
	        /*var light = new pc.Entity();
	        light.name = "light";
	        light.addComponent("light", {
	            type: "point",
	            color: new pc.Color(1, 0, 0),
	            radius: 10
	        });*/

        	this.cameraOffset = new pc.Entity('cameraOffset');
			this.cameraRotate = new pc.Entity('cameraRotate'); 
	        this.camera = new pc.Entity('camera');

	        //
	        var mouseInput = new MouseInput( this.app );
	        var touchInput = new TouchInput( this.app );        
	        var orbitCamera = new OrbitCamera( this.app );         


        	//rotate camera script
	        this.cameraRotate.addComponent('script');
	        var lookCamera = new LookCamera( this.app );
	        this.cameraRotate.script.create( lookCamera.name, { attributes: { camera: this.camera }});	
	        this.cameraOffset.addChild( this.cameraRotate );        

	        // Create an Entity with a camera component
	        var postEffectExample = new PostEffectExample( this.app );

	        this.camera.name = "camera";
	        this.camera.addComponent("camera", {
	            fov: 60,
	            clearColor: new pc.Color(0,0,0,0)
			});
	        this.camera.addComponent('script');

	        //remove skykox layer
			this.layers = this.camera.camera.layers;
	        var skyboxLayer = this.app.scene.layers.getLayerById( pc.LAYERID_SKYBOX );
	        var ido = this.app.scene.layers.getOpaqueIndex( skyboxLayer );			
			this.layers.splice( this.layers.indexOf( ido ), 1); 
			//
			this.camera.script.create( postEffectExample.name, {
        	attributes: {
        		vs: vs,
        		fs: fs
	      	}});	
	      	this.camera.script.posteffectExample.enabled = false;

	        // Make the camera orbit
	        this.camera.script.create( orbitCamera.name );
	        this.camera.script.create( mouseInput.name );
	        this.camera.script.create( touchInput.name );

	        this.camera.script.orbitCamera.enabled = false;

	        this.cameraRotate.addChild( this.camera );        

	        // Add the new Entities to the hierarchy
	         this.app.root.addChild( this.cameraOffset );	

      		///try to add fxaa
	        //this.effect = new pc.FxaaEffect(this.app.graphicsDevice);
    		//var queue = this.camera.camera.postEffects;
    		//queue.addEffect(this.effect);


			/*var box1 = new pc.Entity();
			box1.addComponent("model", { type: "box", });

			this.cameraOffset.addChild(box1);
			box1.setPosition(-1, 0, 0);      

                this.app.assets.loadFromUrl(
                    "/public/pano/01_g01/pano_b.basis",
                    "texture",
                    function (err, asset) {
                    	console.log('texture ok', asset );
                        var material = new pc.StandardMaterial();
                        material.diffuseMap = asset.resource;
                        material.update();
                        box1.model.meshInstances[0].material = material;
                    }
                ); */

            //video element
		    var video = document.createElement('video');
		    video.id = "video";
		    video.loop = true;
		    video.muted = true;
		    video.playsInline = true;
		    video.crossOrigin = "anonymous";
		    var style = video.style;
		    style.width = '1px';
		    style.height = '1px';
		    style.position = 'absolute';
		    style.opacity = '0';
		    style.zIndex = '-1000';
		    style.pointerEvents = 'none';
		    document.body.appendChild(video);

            console.timeEnd('App');
            //
            this.loadAssets( this.readyCall );
	    },

	    // methods for scene
	    resize: function () {
	        var size = this.app.resizeCanvas( this.canvas.width, this.canvas.height);
	        this.canvas.style.width = '';
	        this.canvas.style.height = '';
	    },

	    loadAssets: function( callback ) {

            var assetsToLoad = [
                new pc.Asset("arrow1.png", "texture", {url: App.ASSETS + 'maps/arrows/arrow1.png'}),
                new pc.Asset("arrow2.png", "texture", {url: App.ASSETS + 'maps/arrows/arrow2.png'})
            ];
			//
            var count =0;
            this.app.assets.on('load', function () {
                count++;
                if (count === assetsToLoad.length) {
                    callback();
                }
            });

            for (var i = 0; i < assetsToLoad.length; i++) {
                this.app.assets.add(assetsToLoad[i]);
                this.app.assets.load(assetsToLoad[i]);
            }

	    },

	    setHDRIMap: function() {
    	    this.app.scene.gammaCorrection = pc.GAMMA_SRGB; //GAMMA_NONE
	        this.app.scene.toneMapping = pc.TONEMAP_ACES;   

	        var cubemapAsset = new pc.Asset('helipad', 'cubemap', {
	            url: App.ASSETS + 'hdri/Helipad.dds'
	        }, {
	            "textures": [
	                App.ASSETS + 'hdri/Helipad_posx.png',
	                App.ASSETS + 'hdri/Helipad_negx.png',
	                App.ASSETS + 'hdri/Helipad_posy.png',
	                App.ASSETS + 'hdri/Helipad_negy.png',
	                App.ASSETS + 'hdri/Helipad_posz.png',
	                App.ASSETS + 'hdri/Helipad_negz.png'
	            ],
	            "magFilter": 1,
	            "minFilter": 5,
	            "anisotropy": 1,
	            "name": "Helipad",
	            "rgbm": true,
	            "prefiltered": "Helipad.dds"
	        });
	        this.app.assets.add( cubemapAsset );
	        this.app.assets.load( cubemapAsset );
	        cubemapAsset.ready( () => {
	            this.app.scene.skyboxMip = 2;             
	            this.app.scene.setSkybox(cubemapAsset.resources);
	            this.app.scene.skyboxIntensity = 1.5;
	        });
	    }

};

export default Playcanvas;