var BasicController = require('./BasicController'),
	ProductInstanceModel = require('~/js/model/ProductInstanceModel'),
	ProductInstanceView = require('../view/content/ProductInstanceView'),
	ProductInstance3DView = require('../pc/ProductInstance3DView').default,
    Contentful = require('~/js/Contentful'),	
    App = require("~/js/App");

module.exports = BasicController.extend({
	routes: {
		[App.HOME_DIR + ':id/:idpano/:idproductinstance' ] 			: 'redirectToProduct',
		[App.HOME_DIR + ':id/:idpano/:idproductinstance/:idproduct' ] : 'setProductInstanceView',
	},

	initialize: function() {
		this.model = new ProductInstanceModel();  
	},

	redirectToProduct: function() {
		console.log('redirect to product');
	},

	setProductInstanceView: function( id, idpano, idproductinstance, idproduct ) {
		App.eventBus.on('languageChange', this.onLanguageChange, this);   
		//		
		this.view3D = new ProductInstance3DView( App.pc );
		this.view = new ProductInstanceView( this.view3D );		
		document.body.querySelector('main').appendChild( this.view.el );   
		//
        this.getData( id, idpano, 'onModelFetched' );
	},

	setProductInstanceViewUpdate: function( id, idpano, idproductinstance, idproduct ) {
		this.view3D.setModelBySlug( this.model.get('productInstance')[0].id, idproduct );
		this.view.render( this.model );
	},

	getData: function( id, idpano, callback ) {
		this.id = id;
		this.idpano = idpano;
        this.model.url = Contentful.getEntries( 'pano', 'slug', idpano, 3 );
		this.model.fetch({ id: id }).done( () => { this[ callback ](); });
	},

	onModelFetched: function() {
		this.view.render( this.model );
		this.view3D.init( this.model );
	},

	onModelFetched2Dupdate: function() {
		this.view.render( this.model );
	},

	onLanguageChange: function() {
        this.getData( this.id, this.idpano, 'onModelFetched2Dupdate' );
	},	

	remove: function() {
		App.eventBus.off('languageChange', this.onLanguageChange, this);  
		//
		App.dispose.view = this.view3D;
		this.view.dispose();
	}
});