module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<a href="#" class="close"></a>\r\n';
 if (data.get('productInstance')[0].products.length > 1 ) { 
__p+='\r\n<div class="left-cont"><div id="left" class="arrow-left"></div></div>\r\n<div class="right-cont"><div id="right" class="arrow-right"></div></div>\r\n';
 } 
__p+='\r\n<div class="desc">\r\n';
 if ( product.video ) { 
__p+='\r\n	<div class="play-video" target="'+
((__t=( product.videoTarget ))==null?'':__t)+
'"><button class=\'videoButton\' value="'+
((__t=( product.video[0].videoFile ))==null?'':__t)+
'"></button></div>\r\n';
 } 
__p+='\r\n<h1>'+
((__t=( product.title ))==null?'':__t)+
'</h1>\r\n<input class="hide-btn" type="checkbox" id="hide-btn" />\r\n<label for="hide-btn">'+
((__t=( product.desc ))==null?'':__t)+
'</label>\r\n</div>\r\n<div class="documents">'+
((__t=( translate.getText( 'popup_documents', 'shortText' ) ))==null?'':__t)+
'</div>\r\n';
 if ( product.state ) { 
__p+='\r\n<form class="state-group">\r\n	';
 _.each( product.state, function( state, index ) { 
__p+='   \r\n			<input type="radio" id="'+
((__t=( state.state ))==null?'':__t)+
'" value="'+
((__t=( state.state ))==null?'':__t)+
'" name="'+
((__t=( state.type ))==null?'':__t)+
'" ';
 if ( index===0 ){ 
__p+='checked';
 } 
__p+='><label for="'+
((__t=( state.state ))==null?'':__t)+
'">'+
((__t=( state.title ))==null?'':__t)+
'</label>\r\n	';
 }); 
__p+='\r\n</form>\r\n';
 } 
__p+='\r\n';
 if ( product.material ) { 
__p+='\r\n<form class="material-group" target="'+
((__t=( product.materialTarget ))==null?'':__t)+
'" id="'+
((__t=( product.id ))==null?'':__t)+
'">\r\n	';
 _.each( product.material, function( material, index ) { 
__p+='   \r\n			<input type="radio" id="'+
((__t=( material.id ))==null?'':__t)+
'" value="'+
((__t=( material.diffuseTint ? material.diffuseTint : material.diffuseBmp.get('file') ))==null?'':__t)+
'" name="material" title="'+
((__t=( material.title ))==null?'':__t)+
'" ';
 if ( index===0 || App[ 'productSetting_' + data.get('productInstance')[0].id + product.id ] === material.id ){ 
__p+='checked';
 } 
__p+='><label for="'+
((__t=( material.id ))==null?'':__t)+
'"><img src="'+
((__t=( material.pictureModel.get('file') ))==null?'':__t)+
'" alt="'+
((__t=( material.title ))==null?'':__t)+
'"></label>\r\n	';
 }); 
__p+=' \r\n</form>\r\n';
 } 
__p+='\r\n';
 if ( product.flowInfo ) { 
__p+='\r\n	<ul class="flow-info">\r\n	';
 _.each( product.flowInfo, function( flowInfo, index ) { 
__p+='   \r\n		<li id="'+
((__t=( flowInfo.id ))==null?'':__t)+
'">\r\n		<span>'+
((__t=( flowInfo.id ))==null?'':__t)+
'</span>\r\n		<div class="popup bottom">\r\n			<h2><a href="'+
((__t=( flowInfo.url ))==null?'':__t)+
'" ';
 if (flowInfo.url){ 
__p+=' class="link"';
 } 
__p+='>'+
((__t=( flowInfo.title ))==null?'':__t)+
'</a></h2>\r\n			<p></p>\r\n			</a>\r\n			<a href="#0" class="close-popup">Close</a>\r\n		</div>\r\n		</li> \r\n	';
 }); 
__p+='\r\n	</ul>\r\n';
 } 
__p+='\r\n\r\n\r\n';
}
return __p;
};
