var BuildingCollection = require('~/js/collection/BuildingCollection'),
    template = require('~/js/template/content/buildingTemplate.html'),
    App = require("~/js/App"),
    Utils = require("~/js/Utils"),
    Contentful = require('~/js/Contentful');

module.exports = Backbone.View.extend({

    id: 'building',

    className: 'building',
       
    tagName: 'section',

    events: {
        "click a": "onClick"
    },

    initialize: function() {
        App.eventBus.on('languageChange', this.onLanguageChange, this);    
        //
        this.getData( this.render );
    },

    render: function() {
        App.breadcrumbs.hide();
        //
        this.el.innerHTML = template( { data: this.collection.models } );
        //set title and meta
        if ( App.LANGUAGE === 'en-US' )
            Utils.setMeta("","2N Virtual Experience is an interactive presentation of our comprehensive access control and door communication solutions in 3D.");
        else
            Utils.setMeta("","2N Virtual Experience je interaktivní forma představující naše kompletní řešení kontroly přístupu a dveřní komunikace ve 3D.");
        //
        return this;
    },

    getData: function ( callback ) {
        this.collection = new BuildingCollection();     
        this.collection.url = Contentful.getEntries( 'building' );
        this.collection.on("change reset add remove", callback, this);
        this.collection.fetch({ reset:true });
    },
    
    update: function() {
        
    },

    //handlers
    onClick: function( evt ){
        evt.preventDefault();
        Utils.addClass( evt.currentTarget.parentNode, 'loader-show' );
        Backbone.history.navigate( evt.currentTarget.pathname, true );
    },

    onLanguageChange: function() {
        this.getData( this.render );
    },

    preDispose: function() {
        Utils.addClass( this.el, 'out' );
    },

    dispose: function() {
        App.eventBus.off('languageChange', this.onLanguageChange ); 
        //
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }
});