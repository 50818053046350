var OverCollection = require('~/js/collection/SolutionCollection'),
    template = require('~/js/template/content/solutionTemplate.html'),
    App = require("~/js/App"),
    Contentful = require('~/js/Contentful'),
    Utils = require("~/js/Utils");

module.exports = Backbone.View.extend({

    id: 'solution',

    className: 'solution',
       
    tagName: 'section',

    events: {
        "click .hotspot a": "onClick",
        "click .tab-content li a":   "onClickTabContent",        
        "change .solution-menu":   "onFormChange",
    },

    initialize: function( id ) {
        this.id = id;
        //
        this.options = { movement: { rotateX : 5, rotateY : 5 } };        
        App.eventBus.on('languageChange', this.onLanguageChange, this);  
        document.addEventListener('mousemove', this.onMouseMove.bind(this), false );
        window.addEventListener("resize", this.onWindowResize.bind(this), false);    
        //
        this.getData( this.render );
    },

    render: function() {
        this.el.innerHTML = template( { id: this.id, data: this.collection.models } );
        this.setSolutionByIndex( App.solution || 0 );
        this.setHotspotPos();
        this.init3d();        
        //
        App.breadcrumbs.set( this.collection.at(0).get('id') );
        return this;
    },

    getData: function ( callback ) {
        this.collection = new OverCollection();     
        this.collection.url = Contentful.getEntries( 'building', 'slug', this.id, 4 );
        this.collection.on("change reset add remove", callback, this);
        this.collection.fetch({ reset:true });
        //
        this.pic = this.collection;  //fake collection for init condition 
    },

    //handlers
    onFormChange: function(e){
        e.preventDefault();
        var id = e.target.id;       
        var name = e.target.name;
        var val = e.target.value;
        //
        this.setSolutionByIndex( val );
    }, 

    setSolutionByIndex: function ( index ) {
        //change class for showing hotspots for current solution
        Utils.toggleClassByPrefix(this.el, 'solution-' + index, 'solution-' );
        //change background by solution type, if different
        if ( this.pic.get('file') !== this.collection.at( index ).get('picture').get('file') ){
            this.pic = this.collection.at( index ).get('picture');
            //
            var img = new Image();
            img.src = this.pic.get('file');
            img.onload = () => {
                this.el.querySelector('.background').style.backgroundImage = "url('" + this.pic.get('file') + "')";
                this.el.querySelector('.scene').style.backgroundImage = "url('" + this.pic.get('file') + "')";
                //
                App.dispose.view.preDispose();
            };
        }
        var pictureSelection = this.collection.at( index ).get('pictureSelection');

        this.el.querySelector('.selection-bg').style.backgroundImage = "url('" + pictureSelection.get('file') + "')";
        //
        this.index = index;
        App.solution = index; //store selection globally
        //due the SVG inline change stroke color
        Utils.toggleClassByPrefix( document.body, 'solution-' + this.collection.at( index ).get('icon'), 'solution-' );
        //set radiobutton - due the any previous selection
        this.el.querySelector( '#' + this.collection.at( index ).cid ).checked = true;

        //reposition hotspots
        this.setHotspotPos();
    },

    onClick: function( evt ){
        evt.preventDefault();
        var parent = evt.currentTarget.parentNode;
        //
        var value = "50% 50%, " + parent.offsetLeft + "px " + parent.offsetTop + "px";
        document.documentElement.style.setProperty('--startPos', value );

        Utils.addClass( parent, 'loader-show' );        
        Backbone.history.navigate( evt.currentTarget.pathname, true );
    },

    onClickTabContent: function( evt ){
        evt.preventDefault();
        window.open(evt.currentTarget.href, '_blank').focus();
    },

    onLanguageChange: function() {
        this.getData( this.render );
    },

    //3d images
    init3d: function() {
        this.currentScene = this.el.querySelector('.scene');
    },
    
    getMousePos: function ( evt ) {
        var posx = 0;
        var posy = 0;
        if (!evt) var evt = window.event;
        if ( evt.pageX || evt.pageY )     {
            posx = evt.pageX;
            posy = evt.pageY;
        }
        else if ( evt.clientX || evt.clientY )    {
            posx = evt.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            posy = evt.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }
        return {
            x : posx,
            y : posy
        };
    },

    setHotspotPos: function () {
        var windowWidth = window.innerWidth,
        windowHeight = window.innerHeight,
        windowAspectRatio = windowWidth / windowHeight,
        imageWidth = this.pic.get('dimension').width,
        imageHeight = this.pic.get('dimension').height,
        imageAspectRatio = imageWidth / imageHeight;
        //
        this.el.querySelectorAll('.hotspot.solution' + this.index ).forEach( (hotspot, index) => {
            var pos = hotspot.getAttribute('pos').split(',');
            var hsHeight = hotspot.querySelector('a').clientHeight; 

            //snap HS to borders on low resolution devices
            if ( Number(pos[0]) > windowWidth ) pos[0] = windowWidth - 100;
            if ( Number(pos[0]) < 0 && -Number(pos[0]) > windowWidth +100 ) pos[0] = -windowWidth;

            if ( windowHeight/2-50 < Number(pos[1])  ) pos[1] = windowHeight/2 - pos[1];

            var xPos = pos[0] || 0,
                yPos = pos[1] || 0,
                depth = pos[2] || 0;
            //
            if (windowAspectRatio > imageAspectRatio) {
                //mobile landscape
                yPos = ( yPos / imageHeight) * 100;
                xPos = (xPos / imageWidth) * 100;
            } else {
                //desktop and portrait
                yPos = ((yPos / (windowAspectRatio / imageAspectRatio)) / imageHeight) * 100;
                xPos = ((xPos / (windowAspectRatio / imageAspectRatio)) / imageWidth) * 100;
            }
            //
            hotspot.style.marginLeft = xPos + '%';
            hotspot.style.marginTop = yPos + '%';
            //
            hotspot.style.transform = 'translate3d(0,0,' + depth + 'px)'; //itemImg.getAttribute('data-transform-z')
            //hotspot.querySelector('img').style.transform = 'translate3d(0,0,' + Number( depth ) - 2 + 'px)'; //itemImg.getAttribute('data-transform-z')
        });

    },

    onMouseMove: function( evt ) {
        window.requestAnimationFrame( () => {
            this.rotateSlide( this.getMousePos( evt ) );
        });
    },

    rotateSlide: function( mousepos ) {
        if ( !this.pic.get('dimension') ) return;
        // transform values
        var rotX = this.options.movement.rotateX ? 2 * this.options.movement.rotateX / this.pic.get('dimension').height * mousepos.y - this.options.movement.rotateX : 0,
            rotY = this.options.movement.rotateY ? 2 * this.options.movement.rotateY / this.pic.get('dimension').width * mousepos.x - this.options.movement.rotateY : 0;
        
        this.currentScene.style.transform = 'rotate3d(1,0,0,' + rotX + 'deg) rotate3d(0,1,0,' + rotY + 'deg)';
    },

    onWindowResize: function( evt ) {
        this.setHotspotPos();
    },

    preDispose: function() {
        Utils.addClass( this.el, 'out' );
    },

    dispose: function() {
        App.eventBus.off('languageChange', this.onLanguageChange ); 
        document.removeEventListener('mousemove', this.onMouseMove );
        window.removeEventListener("resize", this.onWindowResize );   
        //
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }
});