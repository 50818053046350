module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="background">\r\n	<div class="scene">\r\n		<div class="selection-bg"></div>\r\n		<div class="selection">	\r\n	';
 data.forEach( function ( data, index ) { 
__p+='    \r\n\r\n		';
 if ( data.get('panoCollection') ) { 
__p+='\r\n			';
 data.get('panoCollection').forEach( function ( panoModel, indexHS ) { 
__p+=' \r\n				<div class="hotspot solution'+
((__t=( index ))==null?'':__t)+
'" id='+
((__t=( panoModel.cid ))==null?'':__t)+
' pos='+
((__t=( panoModel.get('cssPosition') ))==null?'':__t)+
' style="transition-delay : '+
((__t=( indexHS * 0.25 ))==null?'':__t)+
's;"><div class="spinner"></div><a href="'+
((__t=( id ))==null?'':__t)+
'/'+
((__t=( panoModel.get('slug') ))==null?'':__t)+
'" title="'+
((__t=( panoModel.get('title').replace("<br>"," ") ))==null?'':__t)+
'"><img src="'+
((__t=( panoModel.get('cssThumb').get('file') ))==null?'':__t)+
'" alt="'+
((__t=( panoModel.get('title') ))==null?'':__t)+
'">'+
((__t=( panoModel.get('title') ))==null?'':__t)+
'</a></div>\r\n			';
 }); 
__p+='      \r\n		';
 }; 
__p+=' \r\n\r\n	';
 }); 
__p+='     \r\n		</div>\r\n	</div>\r\n</div>\r\n\r\n<form class="solution-menu">\r\n  <div class="tabs">\r\n';
 data.forEach( function ( data, index ) { 
__p+='    \r\n    <div class="tab">\r\n		<input type="radio" name="solution-tabs" id="'+
((__t=( data.cid ))==null?'':__t)+
'" value="'+
((__t=( index ))==null?'':__t)+
'" ';
 if ( index===0 ) { 
__p+='checked';
 } 
__p+=' class="tab-switch">\r\n		<label for="'+
((__t=( data.cid ))==null?'':__t)+
'" class="tab-label"><svg><use xlink:href="#solution-'+
((__t=( data.get('icon') ))==null?'':__t)+
'"></use></svg><span>'+
((__t=( data.get('title') ))==null?'':__t)+
'</span></label>\r\n		<div class="tab-content">\r\n			<ul>\r\n				<li>'+
((__t=( data.get('desc') ))==null?'':__t)+
'</li>\r\n  			</ul>\r\n		</div>\r\n	</div>\r\n';
 }); 
__p+='      \r\n  </div>\r\n';
}
return __p;
};
