module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if ( App.LANGUAGE === 'en-US' ) { 
__p+='\r\n<div class="top-bar-text">Register for the next episode of 2N On Air, which will air on 23 September 2021.</div><div class="top-bar-button"> <a class="link-top-bar" href="https://www.2nevents.com/events/2nonair&lang=en">Register now</a> </div>\r\n';
 }else { 
__p+='\r\n<div class="top-bar-text">Registrujte se na další díl série 2N On Air, který vysíláme 23. září 2021.</div><div class="top-bar-button"> <a class="link-top-bar" href="https://www.2nevents.com/events/2nonair&lang=cs">Registrovat se</a> </div> \r\n';
 } 
__p+='';
}
return __p;
};
