import protoScript from "~/js/lib/ProtoScript";
import App from '~/js/App';
import Utils from '~/js/Utils';

var Material = function(){
    this.name = 'material';
    //
    this.attributes = {
        'element': { type: "string", default:"" }
    };
};

Material.prototype.initialize = function() {

};
Material.prototype.update = function( dt ) {

};

Material.prototype.change = function( target, val ) {
	var targets = target.split('|');
	var entity = this.entity.children.filter( child => {  return child.name === "gltf"; })[0];
	var materials = [];
	var doTween = false;
	//
    for (var j = 0; j < targets.length; j++) {
		var nodeName = targets[j].split(',')[0];
		var materialName = targets[j].split(',')[1];
		//filter meshinstance by name and material
		var meshInstances = entity.model.meshInstances.filter( mesh => { return mesh.node.name === nodeName && mesh.material.name === materialName; });
		
		//change bitmap
		if ( val.startsWith("//") ) {
		    for (var i = 0; i < meshInstances.length; i++) {

		    	this.loadtexture( meshInstances[i].material, App.ASSETS + 'maps/' + val.substr(val.lastIndexOf("/")+1), ( material, asset ) => {
		    		material.diffuseMap = asset.resource;
		    		material.diffuseMapChannel = 'rgb';
		    		material.update();
		    		//
		    		console.log( asset.resource );
		    	});

		    }
		} else {
			doTween = true;
			var rgb = Utils.hexToRgb( val ); //Utils.invertHex( 
			var toColor = new pc.Color(rgb.r, rgb.g, rgb.b );
			//
		    for (var i = 0; i < meshInstances.length; i++) {
		    	materials.push( meshInstances[i].material );
		    }
		}

    }
    //
	if ( doTween ) {
		var color = materials[0].diffuse;
		this.app
		.tween( color )
		.to( toColor , 1,0, pc.QuadraticInOut )
		.on('update', () => {
			for (var i = 0; i < materials.length; i++) {
	
				if ( materials[i].name.includes("Invert") ) {
					var rgb = Utils.hexToRgb( Utils.invertHex( color.toString() ) );

	    			materials[i].diffuse = new pc.Color( rgb.r, rgb.g, rgb.b );
				} else {
	    			materials[i].diffuse = color;
				}
	
		    	materials[i].update();
			}
		})
		.start();
	}
};

Material.prototype.loadtexture = function( material, url, callback ) {

    var assetsToLoad = [
        new pc.Asset( url.substr(url.lastIndexOf("/")+1), "texture", {url: url }),
    ];
	//
    var count =0;
    this.app.assets.on('load', function ( asset ) {
        count++;
        if (count === assetsToLoad.length) {
            callback( material, asset );
        }
    });

    for (var i = 0; i < assetsToLoad.length; i++) {
        this.app.assets.add(assetsToLoad[i]);
        this.app.assets.load(assetsToLoad[i]);
    }

};

export default protoScript( Material );
//