var Utils = require('~/js/Utils');
import protoScript from "~/js/lib/ProtoScript";

var PppProduct = function(){
    this.name = 'pppProduct';
    //
    this.attributes = {
        'id'   : { type: "string", default:"" },
        'rotation': { type: "vec3" }
    };
};

PppProduct.prototype.initialize = function() {
    console.log('ror', this.rotation );
    
    this.entity.rotateLocal( this.rotation );

    this.camera = this.app.root.findByName('camera');
    this.alpha = -1.0;
    this.inertiaFactor = 0.03;
    this.targetX = 0;
    this.targetY = 0;
};
//handlers
PppProduct.prototype.update = function (dt) {
    var t = this.inertiaFactor === 0 ? 1 : Math.min(dt / this.inertiaFactor, 1);
    var element = document.getElementById( this.id );
    //
    if ( element ) {
        this.alpha += ( Math.min( 1.0, this.entity.forward.dot( this.camera.forward ) * 3 ) - this.alpha ) * 0.1;
        var point = this.camera.camera.worldToScreen( this.entity.getPosition() );
        this.targetX = pc.math.lerp(this.targetX, point.x, t);
        this.targetY = pc.math.lerp(this.targetY, point.y, t);
        //
        element.style.top = ( this.targetY) + "px"; //(this.popupPosition.y - this.popup.offsetHeight - 70) + "px";
        element.style.left = this.targetX - element.offsetWidth / 2 + "px";
        element.style.opacity = this.alpha;
    } 
};
export default protoScript( PppProduct );