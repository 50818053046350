var Contentful = require('~/js/Contentful'),
    App = require("~/js/App"),
    Utils = require('~/js/Utils'),
    Renderer = require('@contentful/rich-text-html-renderer');

module.exports = Backbone.Model.extend({
    defaults: {
        title: '',
        position: '',
        sphereLink: '',
        cameraRotation: '0,0',        
        cameraPosition: '',
        productInstance: []
	},

    fetch: function(options) {
        this.parentSlug = options.id;
        return Backbone.Model.prototype.fetch.call(this, options);
    },

    parseDocuments: function( data ) {
        if ( !data ) return null;
        var documents = [];
        data.split('\n').forEach( ( document ) => {
            var temp = document.split(/:(.+)/); //split only first occurence
            if ( temp.length ) {
                var ext = Utils.getUrlExtension( temp[1] );
                documents.push( { title : temp[0], ext:ext, url: temp[1].trim()  } );
            }
        });
        return documents;
    },

	parse : function( res ) {
		//console.log( 'res', res );
        var productInstanceSlug = Utils.getURLpart( window.location.pathname, 3);        
		//
        var entry = res.includes ? res.includes.Entry : null;   

        //filter data by parent url
        res.items = res.items.filter( item => { return Contentful.getInclude( entry, item.fields.parent.sys.id )[0].fields.slug === this.parentSlug; });

        var filterField = entry.filter( ( item ) => {
            return item.fields.slug === productInstanceSlug;
        })[0];

        var productInstance = [];
        if ( filterField ) {
            productInstance[0] = filterField.fields;
            productInstance[0].id = filterField.sys.id;         
            productInstance[0].scale = filterField.fields.scale ? Utils.stringToVec3( filterField.fields.scale ) : Utils.stringToVec3('1,1,1');
            //3d models in instance
            var products = [];
            var changeCubemap = filterField.fields.changeCubemap ? filterField.fields.changeCubemap.split(",") : null;
            productInstance[0].products.forEach( ( product, i ) => {
                var obj = {};
                obj.id = product.sys.id;
                obj.slug = Contentful.getInclude( entry, product.sys.id )[0].fields.slug;
                obj.title = Contentful.getInclude( entry, product.sys.id )[0].fields.title;
                obj.desc = Contentful.getInclude( entry, product.sys.id )[0].fields.desc;
                obj.modelLink = Contentful.getInclude( entry, product.sys.id )[0].fields.modelLink;
                obj.changeCubemap = ( changeCubemap ? changeCubemap[i] : null );
                obj.documents = this.parseDocuments( Contentful.getInclude( entry, product.sys.id )[0].fields.documents );
                obj.desc = Renderer.documentToHtmlString( Contentful.getInclude( entry, product.sys.id )[0].fields.desc );
                if ( Contentful.getInclude( entry, product.sys.id )[0].fields.hasOwnProperty('state') ){
                    var states = [];
                    Contentful.getInclude( entry, product.sys.id )[0].fields.state.forEach( ( state ) => {
                        states.push( Contentful.getInclude( entry, state.sys.id )[0].fields  );   
                    });
                    obj.state = states;
                }

                if ( Contentful.getInclude( entry, product.sys.id )[0].fields.hasOwnProperty('flowInfo') ){
                    var flowInfos = [];
                    Contentful.getInclude( entry, product.sys.id )[0].fields.flowInfo.forEach( ( flowInfo ) => {
                        flowInfos.push( Contentful.getInclude( entry, flowInfo.sys.id )[0].fields  );   
                    });
                    obj.flowInfo = flowInfos;
                }

                //materials
                obj.materialTarget = Contentful.getInclude( entry, product.sys.id )[0].fields.materialTarget;
                if ( Contentful.getInclude( entry, product.sys.id )[0].fields.hasOwnProperty('material') ){
                    var materials = [];
                    Contentful.getInclude( entry, product.sys.id )[0].fields.material.forEach( ( material ) => {
                        var fields = Contentful.getInclude( entry, material.sys.id )[0].fields;
                        fields.id = material.sys.id;
                        fields.pictureModel = fields.picture ? App.assetsCollection.where({ id: fields.picture.sys.id })[0] : null;
                        fields.diffuseBmp = fields.diffuseBmp ? App.assetsCollection.where({ id: fields.diffuseBmp.sys.id })[0] : null;
                        materials.push( fields  );   
                    });
                    obj.material = materials;
                }
                //videos
                obj.videoTarget = Contentful.getInclude( entry, product.sys.id )[0].fields.videoTarget;
                if ( Contentful.getInclude( entry, product.sys.id )[0].fields.hasOwnProperty('video') ){
                    var videos = [];
                    Contentful.getInclude( entry, product.sys.id )[0].fields.video.forEach( ( video ) => {
                        var fields = Contentful.getInclude( entry, video.sys.id )[0].fields;
                        fields.id = video.sys.id;
                        fields.pictureModel = fields.picture ? App.assetsCollection.where({ id: fields.picture.sys.id })[0] : null;
                        fields.videoFile = fields.videoFile;
                        fields.autoPlay = fields.autoPlay;
                        videos.push( fields );   
                    });
                    obj.video = videos;
                }
                //
                products.push( obj );
            });
            productInstance[0].products = products;
        }
        //
        this.set('cameraPosition', res.items[0].fields.hasOwnProperty('cameraPosition') ? Utils.stringToVec3( res.items[0].fields.cameraPosition) : null, {silent: true} );
        this.set('cameraRotation', res.items[0].fields.hasOwnProperty('cameraRotation') ? Utils.stringToVec2( res.items[0].fields.cameraRotation ) : Utils.stringToVec2('0,0'), {silent: true} );               
        this.set('sphereLink', res.items[0].fields.sphereLink, {silent: true} );
        //
        this.set('id', productInstance[0] ? productInstance[0].id : null, {silent: true} );        
        this.set('position', productInstance[0].hasOwnProperty('position') ? Utils.stringToVec3( productInstance[0].position ) : null, {silent: true} );
        this.set('rotation', productInstance[0].hasOwnProperty('rotation') ? Utils.stringToVec3( productInstance[0].rotation ) : null, {silent: true} );        
        this.set('yawPitch', productInstance[0].hasOwnProperty('yawPitch') ? Utils.stringToVec2( productInstance[0].yawPitch ) : Utils.stringToVec2( '0,0' ), {silent: true} ); 
        this.set('fov', productInstance[0].hasOwnProperty('fov') ? productInstance[0].fov : 60, {silent: true} );
        this.set('distance', productInstance[0].hasOwnProperty('distance') ? productInstance[0].distance : 0.5, {silent: true} );        
	    this.set('title', productInstance[0].title, {silent: true} );
        this.set('productInstance', productInstance, {silent: false} );
		//console.log( 'model', this );
    }


});