var Model = require('../model/LanguageModel'),
    App = require("~/js/App"),
	Contentful = require('~/js/Contentful');

module.exports = Backbone.Collection.extend({
        
    model: Model,

	url: Contentful.getLocales(),

	parse : function( res ) {
		var data = [];
		var languageCode = navigator.language || navigator.userLanguage;
		
		_.each( res.items, function( res, index ) {

			if ( res.code.includes( languageCode ) )
				App.LANGUAGE = res.code;

			if ( App.LANGUAGE === 'cs-CZ' )
				App.CURRENCY = 'CZK';

			data.push( new Model({
				name: res.name,
				code: res.code,
				currency: res.code === 'cs-CZ' ? 'CZK' : 'EUR',
				image: App.ASSETS + 'img/flags/' + res.code + '.png'		
        	}));

		});
		//
		return data;
    }
});