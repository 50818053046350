module.exports = {

	HOME_DIR: '', // '' '2nvirtualexperience/'

    ASSETS: '/public/', // '/public/' '/2nvirtualexperience/public/'

	LANGUAGE: 'en-US', //default lng

	CURRENCY: 'EUR', //default lng	

	COOKIE_BAR: false,

	dispose: {
  		_view: { preDispose: function(){}},

		set view( val ) {
			this._view = val;
		},

		get view() {
			return this._view;
		}
	}

};