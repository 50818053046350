var BuildingModel = require('../model/BuildingModel'),
	PanoModel = require('../model/PanoModel'),
	PanoCollection = require('./PanoCollection'),
	SolutionModel = require('../model/SolutionModel'),
	Contentful = require('~/js/Contentful'),
	App = require("~/js/App"),
	Renderer = require('@contentful/rich-text-html-renderer');

module.exports = Backbone.Collection.extend({
        
    model: SolutionModel,

	parse : function( res ) {
		//console.log('res', res );
		var entry = res.includes.Entry;	
		var id = res.items[0].sys.id;
		var data = [];
		
		res.items[0].fields.solution.forEach( ( res, index ) => {
			if ( Contentful.getInclude( entry, res.sys.id )[0].fields.hasOwnProperty('pano') ){
				var panoCollection = new PanoCollection();
				Contentful.getInclude( entry, res.sys.id )[0].fields.pano.forEach( ( pano, index ) => {
					var panoModel = new PanoModel();
					panoModel.set('title', Contentful.getInclude( entry, pano.sys.id )[0].fields.title );
					panoModel.set('slug', Contentful.getInclude( entry, pano.sys.id )[0].fields.slug );
					panoModel.set('cssPosition', Contentful.getInclude( entry, pano.sys.id )[0].fields.cssPosition || '' );
					panoModel.set('cssThumb', App.assetsCollection.where({ id: Contentful.getInclude( entry, pano.sys.id )[0].fields.cssThumb.sys.id })[0] );
					panoCollection.add( panoModel );					
				});
			};
			//
			data.push( new SolutionModel({
				id: index === 0 ? id : index,
				title: Contentful.getInclude( entry, res.sys.id )[0].fields.title,
				slug: Contentful.getInclude( entry, res.sys.id )[0].fields.slug,
				picture : App.assetsCollection.where({ id: Contentful.getInclude( entry, res.sys.id )[0].fields.picture.sys.id })[0],
				pictureSelection : Contentful.getInclude( entry, res.sys.id )[0].fields.hasOwnProperty('pictureSelection') ? App.assetsCollection.where({ id: Contentful.getInclude( entry, res.sys.id )[0].fields.pictureSelection.sys.id })[0] : null,				
				icon : Contentful.getInclude( entry, res.sys.id )[0].fields.icon,
				desc: Renderer.documentToHtmlString( Contentful.getInclude( entry, res.sys.id )[0].fields.desc ),
				panoCollection: ( panoCollection || null)
        	}));
		});
		//console.log('xx', data );
		return data;
    }
});