module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="popup">\r\n	<div id="close" class="close-icon" ><span class="closeicon"></span></div>\r\n	';
 if ( data.title ) { 
__p+='<h1>'+
((__t=( translate.getText( data.title, 'shortText' ) ))==null?'':__t)+
'</h1>';
 } 
__p+='\r\n	<div class="content">\r\n	';
 if ( data.text ) { 
__p+=''+
((__t=( Utils.fillTemplate( translate.getText( data.text, 'longText' ), data.variable ) ))==null?'':__t)+
'';
 } 
__p+='</div>\r\n	';
 if ( data.input ) { 
__p+='<input type="text" id="" value="'+
((__t=( Utils.fillTemplate( translate.getText( data.input, 'shortText' ), data.variable ) ))==null?'':__t)+
'" />';
 } 
__p+='\r\n	';
 if ( data.select  && data.select.length > 1 ) { 
__p+='\r\n		<div class="dropdown">\r\n			<a href="#contact" aria-controls="contact" role="button" data-toggle="dropdown" id="my-dropdown2-btn">---</a>		\r\n			<ul id="contact">\r\n				';
 data.select.forEach( function( dropItem ) { 
__p+='\r\n					<li><a href="#" id="'+
((__t=( dropItem.cid ))==null?'':__t)+
'">'+
((__t=( dropItem.get('company') ))==null?'':__t)+
'</a></li>\r\n				';
 }) 
__p+='\r\n			</ul>\r\n		</div>\r\n	';
 } 
__p+='\r\n\r\n	';
 if ( data.filelist ) { 
__p+='\r\n		<ul class="filelist">\r\n		';
 data.filelist.forEach( function( item ) { console.log(item); 
__p+='\r\n			<li><a href="'+
((__t=( item.url ))==null?'':__t)+
'" target="_blank">'+
((__t=( item.title ))==null?'':__t)+
'</a></li>\r\n		';
 }); 
__p+='\r\n		</ul>\r\n	';
 }; 
__p+='\r\n\r\n	<div class=\'buttons\'>\r\n		';
 if ( data.button_no ) { 
__p+='<button type=\'button\' class=\'no text border\'>'+
((__t=( translate.getText( data.button_no, 'shortText' ) ))==null?'':__t)+
'</button>';
 } 
__p+='\r\n		';
 if ( data.button_yes ) { 
__p+='<button type=\'button\' class=\'yes text border\'>'+
((__t=( translate.getText( data.button_yes, 'shortText' ) ))==null?'':__t)+
'</button>';
 } 
__p+='\r\n	</div>\r\n</div>\r\n';
}
return __p;
};
