var Model = require('../model/SideMenuModel'),
	App = require("~/js/App"),
	Contentful = require('~/js/Contentful');

module.exports = Backbone.Collection.extend({
        
    model: Model,

	parse : function( res ) {
		//console.log('res', res );		
		//
		var data = [];
		var entry = res.includes.Entry;	
		//
		res.items.forEach( ( res, index ) => {
			data.push( new Model({
				id: res.sys.id,
		    	order : res.fields.order ? res.fields.order : 0,
		    	title : res.fields.title ? res.fields.title : {},
		    	slug : res.fields.slug ? res.fields.slug : '',
				parent : res.fields.parent ? res.fields.parent.sys.id : null,		    	
		    	reference : res.fields.reference ? Contentful.getInclude( entry, res.fields.reference.sys.id )[0].fields.slug : null,
		    	picture : res.fields.picture ? App.assetsCollection.where({ id: res.fields.picture.sys.id })[0] : null
        	}));

		});
		//
		//console.log(data);
		return _.sortBy(data, function(o) { return o.get('order'); });
    }   
});
