var App = require("~/js/App"),
	Utils = require('~/js/Utils');

var Direction = require('../pc.script/Direction').default;

let instance = null;

class PanoDirections {

	constructor ( app ) {
		this.app = app;		
		//
	    if ( !instance ){
			instance = this;
	    }
		return instance;
	}

	create( cameraPos, directions ) {
		return new Promise( (resolve) => {		
			//create root holder
			this.root = new pc.Entity('directions');
			this.app.root.addChild( this.root );
			//
			var cameraOffsetPos = Utils.stringToVec3( cameraPos );
	    	directions.forEach( ( dir, index ) => {
	    		var direction = new pc.Vec3();
	    		direction.sub2( cameraOffsetPos, Utils.stringToVec3( dir.cameraPosition ) ).normalize();

				var position = new pc.Vec3();
				var distance = -1;
				position.add2( cameraOffsetPos, direction.scale( distance ) );

		        var box = new pc.Entity();
		        box.name = "dir" + index;
		        box.setPosition( position.x, position.y-0.2, position.z );
				box.lookAt( cameraOffsetPos );
		       	box.addComponent('script');	      

		        var direction = new Direction( this.app );
		        box.script.create( direction.name, { attributes: { 
		        	id : dir.id,		        	
		        	sphereLink : dir.sphereLink,
		        	slug : dir.slug,
		        	correction: dir.correction,
		        	correctionWithTransition: dir.correctionWithTransition
		        }});		       	
		        this.root.addChild(box);
	    	});
		    resolve();	    	
	    });
	}

	dispose() {
        for (var i = this.root.children.length - 1; i >= 0; --i) {
            if( this.root.children[i] instanceof pc.Entity ){
                this.root.children[i].destroy();
            }
        }
		//dispose root holder		
		this.app.root.removeChild( this.root );
		delete this.root;		
		this.root = null;
	}
  };

export { PanoDirections };