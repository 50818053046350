import { PanoModels } from './nested/PanoModels';
import App from '~/js/App';

class Product3DView {

	constructor ( pc ) {
		this.name = 'Product3DView';
	    this.pc = pc;
	    //
	    this.panoModels = new PanoModels( this.pc.app );	 
	}

	init( model ) {
		//save control state of cameras for dispose
		this.lookCameraState = this.pc.cameraRotate.script.lookCamera.enabled;
		this.orbitCameraState = this.pc.camera.script.orbitCamera.enabled;
		//
		//reset rotate camera
		this.pc.cameraOffset.setLocalPosition( 0,0,0 );
		this.pc.cameraRotate.script.lookCamera.setCameraRotation( 0, 0 );
      	this.pc.cameraRotate.setLocalEulerAngles( 0, 0, 0 );
      	this.pc.cameraRotate.script.lookCamera.enabled = false;	      	
   		//reset orbit camera
        this.pc.camera.script.orbitCamera.enabled = true;	      	
   		this.pc.camera.script.orbitCamera.fov = 60;        
      	this.pc.camera.script.orbitCamera._pivotPoint = new pc.Vec3(0,0,0);
		this.pc.camera.script.orbitCamera._targetPivotPoint = new pc.Vec3(0,0,0);

		this.pc.camera.script.orbitCamera._distance = 0.5;	   	
      	this.pc.camera.script.orbitCamera.distance = 0.5;	      	

		this.pc.camera.script.orbitCamera.yaw = 180;
		this.pc.camera.script.orbitCamera.pitch = 0;
		//
		this.pc.camera.script.posteffectExample.enabled = false;

		//check if any previous models exist - hack
		if ( this.panoModels.root.findByName("soloView") && this.panoModels.root.findByName("soloView").children.length )
			this.panoModels.dispose('');

		this.panoModels.create( model.get('productInstance') );		 
		App.dispose.view.preDispose( this.name );  

		//orbit camera must be enable twice :()
		this.pc.camera.script.orbitCamera.enabled = true;
      	this.pc.cameraRotate.script.lookCamera.enabled = false;	 		
	}
	update( model ) {
		this.panoModels.create( model.get('productInstance') );	
	}
	//view methods
	playAnimationClip( modelInstanceId, animationClip ) {
		var currentModel = this.getCurrentModel( modelInstanceId );
		currentModel.script.loadModel.switchToClipByName( animationClip );
	}

	changeMaterial( modelInstanceId, target, val ) {
		var currentModel = this.getCurrentModel( modelInstanceId );
		currentModel.script.material.change( target, val );
	}
	playVideo( modelInstanceId, target, val ) {
		var currentModel = this.getCurrentModel( modelInstanceId );
		currentModel.script.video.play( target, val );
	}
	//tools
	getCurrentModel( modelInstanceId ) {
		var currentModel = this.panoModels.root.findByName( modelInstanceId ).children.filter( ( child ) => {
			return child.enabled === true ;
		})[0];
		return currentModel;
	}

	//dispose methods
	preDispose( id ) {
		console.log('__product predispose', id);
		this.dispose( id );
	}

	dispose( id ) {
		console.log('__product dispose', id);
        this.pc.camera.script.orbitCamera.enabled = this.orbitCameraState;
		//tween entities to defaults	    
		this.pc.camera.setLocalPosition(0,0,0);
		this.pc.camera.setLocalEulerAngles( 0, 0, 0 );	

		this.pc.cameraRotate.script.lookCamera.enabled = this.lookCameraState;
		//if id is undefined dispose is called from any 2d views, it means complete dispose 3D
		if ( typeof id === 'undefined' ) this.disposeAll();
	}

	disposeAll() {
		//complete dispose 3D
		console.log('__product complete dispose!!!');	
		this.panoModels.dispose('');	
	}
  };

export default Product3DView;