var BasicController = require('./BasicController'),
	ProductModel = require('~/js/model/ProductModel'),
	ProductView = require('../view/content/ProductView'),
	Product3DView = require('../pc/Product3DView').default,
    Contentful = require('~/js/Contentful'),	
    App = require("~/js/App");

module.exports = BasicController.extend({
	routes: {
		[App.HOME_DIR + 'product/:id' ] :'setProductView',
	},

	initialize: function() {
		this.model = new ProductModel();  
	},

	setProductView: function( idproduct ) {
		App.eventBus.on('languageChange', this.onLanguageChange, this);   
		//		
		this.view3D = new Product3DView( App.pc );
		this.view = new ProductView( this.view3D );		
		document.body.querySelector('main').appendChild( this.view.el );   
		//
        this.getData( idproduct, 'onModelFetched' );
	},

	setProductViewUpdate: function( idproduct ) {
		this.view3D.disposeAll();
        this.getData( idproduct, 'onModelUpdateFetched' );
	},

	getData: function( idproduct, callback ) {
		this.idproduct = idproduct;
        this.model.url = Contentful.getEntries( 'product', 'slug', idproduct );
		this.model.fetch().done( () => { this[ callback ](); });
	},

	onModelFetched: function() {
		this.view.render( this.model );
		this.view3D.init( this.model );
	},

	onModelUpdateFetched: function() {
		this.view.render( this.model );
		this.view3D.update( this.model );
	},

	onModelFetched2Dupdate: function() {
		this.view.render( this.model );
	},

	onLanguageChange: function() {
        this.getData( this.idproduct, 'onModelFetched2Dupdate' );
	},	

	remove: function() {
		App.eventBus.off('languageChange', this.onLanguageChange, this);  
		//
		App.dispose.view = this.view3D;
		this.view.dispose();
	}
});