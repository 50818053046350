module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul>\r\n';
 _.each( data, function( data, index ) { 
__p+='    	\r\n	';
 if ( data.get('enabled') ) { 
__p+='\r\n		<li>\r\n			<div class="layer-loader">\r\n				<div class="circle item0"></div>\r\n				<div class="circle item1"></div>\r\n				<div class="circle item2"></div>\r\n			</div>		\r\n			<a href="'+
((__t=( data.get('slug') ))==null?'':__t)+
'">\r\n				<div class="title">\r\n					<h2>'+
((__t=( data.get('title') ))==null?'':__t)+
'</h2>\r\n					<p>'+
((__t=( data.get('desc') ))==null?'':__t)+
'</p>\r\n				</div>\r\n			</a>\r\n		</li>\r\n	';
 } 
__p+='\r\n';
 }); 
__p+='      \r\n</ul>';
}
return __p;
};
