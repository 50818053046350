class DebugPC {
	constructor ( app ) {
	    this.app = app;
    	this.app.keyboard.on(pc.EVENT_KEYDOWN, this.#onKeyDown, this);
	}

	#onKeyDown = (event) => {

	    if (event.key === pc.KEY_A ) {
	       this.#assetsList();
	    }
	    if (event.key === pc.KEY_S ) {
	    	this.level = 0;
	    	this.entities = [];
	       this.#sceneList();
	    }
	    if (event.key === pc.KEY_D ) {
	       this.#debug();
	    }
	    event.event.preventDefault();
	}

	#debug = () => {
		console.log(this.app.stats.scene, this.app.stats.vram)	;
		//
	}

	#assetsList = () => {
		console.clear();		
		var assets = this.app.assets._assets;
		assets.forEach( (asset) => {
			console.log( asset.name + ' / ' + asset.type );
		});
	}

	#initEntity = (entity) => {

	    if (entity === undefined )
	        return;

	    this.entities.push(entity);
	    var color = entity.enabled ? 'color:green;' : 'color:grey;';
	    //console.log(entity.script);
	    console.log( "%c" + Array(this.level*2).join(" ") + '-' + entity.name + "( " + entity.getLocalPosition() + " )", color);

	    var i;
	    for (i = 0; i < entity.children.length; ++i) {
	        if ( entity.children[i].name !== 'RootNode' ){
	        	this.level++;
	            this.#initEntity(entity.children[i]);
	        }
	       	this.level--;
	    }
	};

	#sceneList = () => {
		console.clear();		
		this.#initEntity( this.app.root );
		console.log( this.entities );
	}


	update(dt) {
    	var UI_DRAW_CALLS = 7; // UI takes 7 drawcalls to render, for this tutorial, we just want to show forward drawcalls for the boxes
    	console.log( (this.app.stats.drawCalls.forward - UI_DRAW_CALLS).toString() + " / " + (this.app.stats.drawCalls.total).toString() + " / " + (this.app.stats.vram.totalUsed ).toString() );
	}


  };

export { DebugPC };