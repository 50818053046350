var template = require('~/js/template/layout/navbarTemplate.html'),
    App = require("~/js/App"),
    Utils = require('~/js/Utils');

module.exports = Backbone.View.extend({
       
    tagName: 'navbar',

    className: 'header',

    events: {
        "click a.logo":     "onLogoClick",        
        "click .menu-btn":  "onMenuButtonClick"
    },    

    initialize: function() {
        Backbone.history.on('route', this.historyChange, this );    
        App.eventBus.on('languageChange', this.onLanguageChange, this);
        //
        this.render();
    },

    render: function() {
        this.el.innerHTML = template( { App } );
        return this;
    },

    historyChange: function( event ) {
        if ( this.el.querySelector('#menu-btn').checked )
            this.closeMenu();
    },

    onLanguageChange: function() {
        this.render();
        this.openCloseToggle();
    },

    onLogoClick: function( evt ){
        evt.preventDefault();
        Backbone.history.navigate( '/' + App.HOME_DIR, true );
    },    

    closeMenu: function() {
        this.el.querySelector('#menu-btn').checked = !this.el.querySelector('#menu-btn').checked;
        this.openCloseToggle();
    },

    onMenuButtonClick: function( evt ) {
        this.openCloseToggle();
    },

    openCloseToggle: function() {
        if (  Utils.hasClass( document.body, 'menu-open' ) ) 
            Utils.toggleClassByPrefix( document.body, 'menu-close', 'menu-' );
        else
            Utils.toggleClassByPrefix( document.body, 'menu-open', 'menu-' );        
    }

});