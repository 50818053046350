var template = require('~/js/template/layout/contentTemplate.html');

module.exports = Backbone.View.extend({
       
    tagName: 'main',

    className: 'content',

    initialize: function() {
        this.render();
    },

    render: function() {
        this.el.innerHTML = template( {} );        
        return this;
    }
});