var template = require('~/js/template/layout/notificationTemplate.html'),
    App = require("~/js/App"),
    Utils = require("~/js/Utils");

module.exports = Backbone.View.extend({

    className: 'top-bar',
       
    initialize: function() {
        Utils.addClass( document.body , 'notification' );
        App.eventBus.on('languageChange', this.onLanguageChange, this);    
        this.render();
    },

    render: function() {
        //
        this.el.innerHTML = template( {} );
        return this;
    },
    
    onLanguageChange: function() {
        this.render();
    },


    dispose: function() {
        Utils.removeClass( document.body , 'notification' );
        App.eventBus.off('languageChange', this.onLanguageChange ); 
        //
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }
});