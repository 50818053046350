import protoScript from "~/js/lib/ProtoScript";

var LookCamera = function(){
    this.name = 'lookCamera';
    //
    this.attributes = {
        "camera": { type: "entity" },
        "mouseLookSensitivity": { type: "number", default: 0.1, title: "Mouse Look Sensitivity", description: "" },
        "touchLookSensitivity": { type: "number", default: 0.2, title: "Touch Look Sensitivity", description: "" },
        "snappinessFactor": { type: "number", default: 0.2, title: "Look at duration", description: "" },
        "fov": { type: "number", default: 60, title: "focus", description: "" },          
        "fovMin": { type: "number", default: 35, title: "focus min", description: "" },  
        "fovMax": { type: "number", default: 120, title: "focus maxn", description: "" },  
        "fovSensitivity": { type: "number", default: 0.5, title: "zoom Sensitivity", description: "" },        
    };
};

LookCamera.prototype.initialize = function () {
    // Cache some temp variables for use later
    this._tempQuat1 = new pc.Quat();
    this._tempQuat2 = new pc.Quat();
    this._tempVec3_1 = new pc.Vec3();
    
    // Calculate the camera euler angle rotation around x and y axes
    // This allows us to place the camera at a particular rotation to begin with in the scene
    var quat = this.entity.getLocalRotation();
    this.ey = this.getYaw(quat) * pc.math.RAD_TO_DEG;
    this.ex = this.getPitch(quat, this.ey) * pc.math.RAD_TO_DEG;
    
    // The target rotation for the camera to rotate to
    this.targetEx = this.ex;
    this.targetEy = this.ey;
    //fov
    this.targetFov = this.fov;
            
    // Workaround for mouse movement as the first move event can give very large
    // difference moved in screen position 
    this.moved = false;

    // Store the position of the touch so we can calculate the distance moved
    this.lastTouchPosition = new pc.Vec2();

    this.lastPinchDistance = 0;

    this.addEventCallbacks();
};


LookCamera.prototype.addEventCallbacks = function() {
    if (this.app.mouse) {
        this.app.mouse.on(pc.EVENT_MOUSEMOVE, this.onMouseMove, this);
        this.app.mouse.on(pc.EVENT_MOUSEWHEEL, this.onMouseWheel, this);
        
    }
    
    if (this.app.touch) {
        this.app.touch.on(pc.EVENT_TOUCHSTART, this.onTouchStart, this);
        this.app.touch.on(pc.EVENT_TOUCHMOVE, this.onTouchMove, this);
    }
};


LookCamera.prototype.update = function (dt) {
    // Update the camera's orientation to rotate smoothly towards the target rotation
    // By using lerp in this way, the rotation will go slower as it gets closer to
    // the target rotation
    var lerp = 1;
    if (this.snappinessFactor > 0) {
        lerp = dt / this.snappinessFactor;
    }
    
    this.ex = pc.math.lerpAngle( this.ex, this.targetEx, lerp);
    this.ey = pc.math.lerpAngle( this.ey, this.targetEy, lerp);
    this.ey = this.ey % 360,

    this.fov =  pc.math.lerp(this.fov, this.targetFov, lerp);
    this.camera.camera.fov = this.fov;

    this.entity.setLocalEulerAngles(this.ex, this.ey, 0);
    //console.log( "lc", this.ex , this.ey, this.fov );
};

LookCamera.prototype.setCameraRotation = function( rotX, rotY ) {
   this.ex = rotX;
   this.ey = rotY;
   this.targetEx = rotX;
   this.targetEy = rotY;
};

LookCamera.prototype.setCameraFov = function( fov ) {
   this.fov = fov;
   this.targetFov = fov;
};

LookCamera.prototype.moveCamera = function(dx, dy, sensitivity) {
    // Update the current Euler angles, clamp the pitch.
    if (!this.moved) {
        // first move event can be very large
        this.moved = true;
        return;
    }
        
    this.targetEx += dy * sensitivity;
    this.targetEx = pc.math.clamp(this.targetEx, -90, 90);
    this.targetEy = this.targetEy + dx * sensitivity; 
};


LookCamera.prototype.onMouseMove = function (event) {
    // Only update the camera target rotation only if the left mouse
    // button is pressed down
    if (this.app.mouse.isPressed(pc.MOUSEBUTTON_LEFT)) {
        this.moveCamera(event.dx, event.dy, this.mouseLookSensitivity);
    }
};
LookCamera.prototype.onMouseWheel = function (event) {
    //event.preventDefault();
    this.targetFov -= event.wheel * this.fovSensitivity * (this.targetFov * 0.1);   
    this.targetFov =  pc.math.clamp( this.targetFov, this.fovMin, this.fovMax);
};

LookCamera.prototype.onTouchStart = function(event) {
    // We only care about the first touch. As the user touches the screen, 
    // we stored the current touch position
    var touch = event.touches[0];
    this.lastTouchPosition.set(touch.x, touch.y);
};



LookCamera.prototype.onTouchMove = function(event) {
    var touches = event.touches;
    if (touches.length == 1) {
        var touch = touches[0];
        this.moveCamera((touch.x - this.lastTouchPosition.x), (touch.y - this.lastTouchPosition.y), this.touchLookSensitivity);
        this.lastTouchPosition.set(touch.x, touch.y);
    } else if (touches.length == 2) {
        var currentPinchDistance = this.getPinchDistance(touches[0], touches[1]);
        var diffInPinchDistance = currentPinchDistance - this.lastPinchDistance;
        this.lastPinchDistance = currentPinchDistance;
        //
        this.targetFov -= (diffInPinchDistance * this.fovSensitivity * 0.1) * (this.targetFov * 0.1);
        this.targetFov =  pc.math.clamp( this.targetFov, this.fovMin, this.fovMax);
    }
};


LookCamera.prototype.getYaw = function () {    
    var forward = this.entity.forward.clone();
    return Math.atan2(-forward.x, -forward.z);    
};


LookCamera.prototype.getPitch = function(quaternion, yaw) {
    var quatWithoutYaw = this._tempQuat1;
    var yawOffset = this._tempQuat2;
    
    yawOffset.setFromEulerAngles(0, -yaw, 0);
    quatWithoutYaw.mul2(yawOffset, quaternion);
    
    var transformedForward = this._tempVec3_1;
    
    quatWithoutYaw.transformVector(pc.Vec3.FORWARD, transformedForward);
    
    return Math.atan2(transformedForward.y, -transformedForward.z) ;      
};

LookCamera.prototype.getPinchDistance = function (pointA, pointB) {
    // Return the distance between the two points
    var dx = pointA.x - pointB.x;
    var dy = pointA.y - pointB.y;    
    
    return Math.sqrt((dx * dx) + (dy * dy));
};

export default protoScript( LookCamera );
//