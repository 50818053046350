import protoScript from '~/js/lib/ProtoScript';
import App from '~/js/App';
import Utils from '~/js/Utils';

var FlowInfo = require('./FlowInfo').default;

var LoadModel = function(){
    this.name = 'loadModel';
    //
    this.attributes = {
        'scale': {  type: " Number", default:"1.0"},
        'disableHiding'   : { type: "boolean", default: false  } //disable hidding parts of model - due to model viewer
    };
};

LoadModel.prototype.initialize = function() {
    this.currFile = '';
    this.fadeMaterialsList = [];
    //
    this.on('enable', this.onEnable, this);
    this.on('disable', this.onDisable, this);  
    this.on('destroy', this.onDestroy, this);  
    //
    this.onEnable();
};
LoadModel.prototype.update = function( dt ) {};

//handlers
LoadModel.prototype.onEnable = function() {
    Backbone.history.on('route', this.onHistoryChange, this );  
};
LoadModel.prototype.onDisable = function() {
    Backbone.history.off('route', this.onHistoryChange, this );  
};

LoadModel.prototype.onDestroy = function () {
    this.off('enable', this.onEnable, this);
    this.off('disable', this.onDisable, this);  
    this.off('destroy', this.onDestroy, this);    
    Backbone.history.off('route', this.onHistoryChange, this );    
    //
    this.disposeModel();
};

LoadModel.prototype.onHistoryChange = function( event ) {
    //console.log('history change');
    this.fadeMaterials( 0.5 );
};
//
LoadModel.prototype.load = function( file ) {
    this.resolve  = null;
    //
    if ( file != this.currFile ) {
        if ( this.gltf )
            this.disposeModel();
        //
        this.currFile = file;
        var pro = new Promise( (resolve) => {  this.resolve = resolve;  });           
        //console.log('nahravam@@', file  );         
        this.app.assets.loadFromUrl( App.ASSETS + 'models/' + file  + '.glb', "container", (err, asset) => {
            //console.log('nahrano@@', file  ); 
            this.asset = asset;
            this.addLoadedModel(err, asset.resource);
        });
        return pro;
    } else {
        //console.log('nenahravam@@', this.entity.name  );
        return new Promise( (resolve) => { resolve(); });        
    }
};

LoadModel.prototype.addLoadedModel = function(err, res) {

    this.tags = [];
    this.grabTags( res.model.resources[0].graph );
    //console.log('tags', this.tags );
    //


    this.gltf = new pc.Entity('gltf');
    this.gltf.addComponent('model', {
        type: "asset",
        asset: res.model,
        castShadows: true
        //layers: [worldLayer.id, screenLayer.id]
    });
    //
    this.prepareMaterials();
    //
    this.entity.addChild(this.gltf);
    this.entity.setLocalScale( this.scale, this.scale, this.scale );

    //store dimensions of model which I calculate from aabb accross meshinstances
    this.entity.dimensions = this.getModelDimensions( res.model.resources[0] );
    this.entity.script.elementToEntity.addElement( this.entity );
    //
    // create animations
    if (res.animations && res.animations.length > 0) {
        this.gltf.addComponent('animation', {
            assets: res.animations.map(function (asset) {
                return asset.id;
            }),
            speed: 1,
            activate: false //dont play onload
        });

        var animationMap = {};
        for (var i = 0; i < res.animations.length; ++i) {
            var animAsset = res.animations[i];
            animationMap[animAsset.resource.name] = animAsset.name;
        }

        this.animationMap = animationMap;
        //onAnimationsLoaded(Object.keys(this.animationMap));

        var createAnimGraphs = function () {
            var extract = function (value, component) {
                return function () {
                    return value[component];
                };
            };

            var graph = this.graph;

            var recurse = function (node) {
                graph.addGraph(node, new pc.Color(1, 1, 0, 1), extract(node.localPosition, 'x'));
                graph.addGraph(node, new pc.Color(0, 1, 1, 1), extract(node.localPosition, 'y'));
                graph.addGraph(node, new pc.Color(1, 0, 1, 1), extract(node.localPosition, 'z'));

                graph.addGraph(node, new pc.Color(1, 0, 0, 1), extract(node.localRotation, 'x'));
                graph.addGraph(node, new pc.Color(0, 1, 0, 1), extract(node.localRotation, 'y'));
                graph.addGraph(node, new pc.Color(0, 0, 1, 1), extract(node.localRotation, 'z'));
                graph.addGraph(node, new pc.Color(1, 1, 1, 1), extract(node.localRotation, 'w'));

                graph.addGraph(node, new pc.Color(1.0, 0.5, 0.5, 1), extract(node.localScale, 'x'));
                graph.addGraph(node, new pc.Color(0.5, 1.0, 0.5, 1), extract(node.localScale, 'y'));
                graph.addGraph(node, new pc.Color(0.5, 0.5, 1.0, 1), extract(node.localScale, 'z'));

                for (var i = 0; i < node.children.length; ++i) {
                    recurse(node.children[i]);
                }
            };
            recurse(entity);
        };

        // create animation graphs
        //setTimeout(createAnimGraphs.bind(this), 1000);
    }
    //add flow infos
    for (var key in this.tags) {
        if ( key.startsWith("info") ) {
            //var entity = this.entity.findByName( key );
            var entity = new pc.Entity('models');
            entity.name = '_' + key;
            entity.addComponent('script');
            var flowInfo = new FlowInfo( this.app );
            entity.script.create( flowInfo.name, { attributes: { graphNode: this.entity.findByName( key ), elementName: this.tags[key].flow, show: this.tags[key].show, animComponent: this.gltf.animComponent }});
            this.entity.addChild( entity );
        }
    };
    //
    this.resolve();
};

LoadModel.prototype.disposeModel = function () {
    this.entity.removeChild(this.gltf);

    // First destroy the glTF entity...
    if (this.gltf) {
        if (this.gltf.animComponent) {
            this.gltf.animComponent.stopClip();
        }
        this.gltf.destroy();
        this.gltf = null;
    }
    if (this.asset) {
        this.app.assets.remove(this.asset);
        this.asset.unload();
        this.asset = null;
    }
    // Blow away all properties holding the loaded scene
    delete this.asset;
    delete this.animationClips;
    delete this.gltf;
};
//fade materials
LoadModel.prototype.prepareMaterials = function() {
    var meshInstances = this.gltf.model.meshInstances;
    //
    for (var i = 0; i < meshInstances.length; i++) {

        //console.log('mat', meshInstances[i].material.name, meshInstances[i].material.diffuse.toString() )
        if ( this.tags[ meshInstances[i].node.name ] && this.tags[ meshInstances[i].node.name ].hasOwnProperty('hide') ) {
            var mat = meshInstances[i].material.clone();
            //due to right way to restore opacity after tweening
            if ( mat.opacity !==1 )
                mat.opacity_stored = mat.opacity;
            //
            meshInstances[i].material = mat;  
            //if condition for hiding is true, hide them without fading
            mat.blendType = pc.BLEND_NORMAL;            
            if ( this.tags[ meshInstances[i].node.name ].hide === Utils.getURLsegLen() ) {
                mat.opacity = this.disableHiding ? 1 : 0;
            }
            mat.update();    
            //store materials to named array for fading 
            this.fadeMaterialsList[ meshInstances[i].node.name ] = Array.isArray(this.fadeMaterialsList[ meshInstances[i].node.name ]) ? this.fadeMaterialsList[ meshInstances[i].node.name ].concat(mat) : [mat];
        }
    }
};

LoadModel.prototype.fadeMaterials = function( time ) {
    var meshInstances = this.gltf.model.meshInstances;
    //
    for (var i = 0; i < meshInstances.length; i++) {
        if ( this.tags[ meshInstances[i].node.name ] ) {

            this.fadeMaterialsList[ meshInstances[i].node.name ].forEach( ( material ) => {
                var data = { opacity: material.opacity };
                var toOpacity = ( this.tags[ meshInstances[i].node.name ].hide === Utils.getURLsegLen() ? 0 : ( material.hasOwnProperty('opacity_stored') ? material.opacity_stored : 1 ) );
                material.blendType = pc.BLEND_NORMAL;

                this.app
                .tween( data )
                .to( { opacity: toOpacity } , time, pc.Linear )
                .on('update', function () {
                    material.opacity = data.opacity;
                    material.update(); // update material
                })
                .on('complete', function () {     
                    //if ( !material.hasOwnProperty('opacity_stored') )
                    //material.blendType = pc.BLEND_NONE;
                })
                .start();
            });
        }
    }
};

//animations
LoadModel.prototype.switchToClipByName = function(clipName) {
    if (this.gltf && this.gltf.animation) {
        this.gltf.animation.loop = false;
        this.gltf.animation.enabled = true;
        if (clipName) {
            this.gltf.animation.play(this.animationMap[clipName], 0); //0 =blend time
        } else {
            this.gltf.animation.playing = true;
        }
    }
};

//tools
LoadModel.prototype.getModelDimensions = function( model ) {
    //I need calculate aabb from meshinstances
    var meshInstances = model.meshInstances;
    if (meshInstances.length > 0) {
        var bbox = new pc.BoundingBox();
        bbox.copy(meshInstances[0].aabb);
        for (var i = 0; i < meshInstances.length; i++) {

            if ( this.tags[ meshInstances[i].node.name ] && this.tags[ meshInstances[i].node.name ].hasOwnProperty('hide')  ){
              
            } else
                bbox.add(meshInstances[i].aabb);
        }
    }
    var w4 = new pc.Vec3();
    if ( meshInstances.length )
        return w4.copy(bbox.halfExtents).scale(1);
    else
        return w4;
};
LoadModel.prototype.getTags = function( res ) {
    //returns Babylon extras which I use for store tags
    var tags = {};
   
    if ( res.model.graph.children.length ) {
        //kdyz je vic meshu jsou extras tags pod objektem children
        var childTags = [];
        res.model.graph.children.forEach( ( child ) => {
            if ( child.hasOwnProperty('extras')) {
                childTags[ child.name ] = this.toObject( child.extras.tags.split(',') );
            }
        });
        tags =  childTags; 

    } else {
        tags = this.toObject( res.model.graph.hasOwnProperty('extras') ? res.model.graph.extras.tags.split(',') : [] ); 
    }
    return tags;
};

LoadModel.prototype.grabTags = function( modelGraph ) {
    if (modelGraph === undefined )
        return;

    if ( modelGraph.children.length ) {
        modelGraph.children.forEach( ( child ) => {
            if ( child.hasOwnProperty('extras'))
                this.tags[ child.name ] = this.toObject( child.extras.tags.split(',') );
            this.grabTags( child );
        });
    }
};

LoadModel.prototype.toObject = function(arr) {
  var rv = {};
  arr.forEach(function( item ) {
    let temp = item.split('-');
    let val = isNaN( temp[1] ) ? temp[1] : parseInt( temp[1] );
    rv[ temp[0] ] = val;  
  });
  return rv;
};
//
export default protoScript( LoadModel );
