var template = require('~/js/template/content/productTemplate.html'),
    App = require("~/js/App"),
    Utils = require("~/js/Utils");

module.exports = Backbone.View.extend({

    id: 'productInstance',
       
    tagName: 'section',

    events: {
        "change form":   "onFormChange",
        "click a.close": "onCloseClick",        
        "click .left-cont": "onLeftClick",
        "click .right-cont": "onRightClick",
        "click .flow-info li": "onFlowInfoClick",
        "click .flow-info li h2 a": "onFlowInfoUrlClick",                
        "click .flow-info a.close-popup": "onFlowInfoCloseClick",
        "click .documents" : "onDocumentsClick",
        "click .play-video" : "onVideoClick"
    },

    initialize: function( view3D ) {
        this.view3D = view3D;
        this.currIndex = 0;
    },

    render: function( model ) {
        this.model = model;
        var array = this.model.get('productInstance')[0].products;
        var product = array[ this.getProductIndex( array ) ];
        if ( product.state )
            this.currAnimation = product.state[0].state;

        this.el.innerHTML = template( { data: model, product: product, translate: App.translateCollection, App } );
        //
        App.breadcrumbs.set( product.id );
        return this;
    },

    //handlers
    onFormChange: function(e){
        e.preventDefault();
        var id = e.target.id;       
        var name = e.target.name;
        var val = e.target.value;
        //
        if ( name === 'animation') {
            this.currAnimation = val;
            this.view3D.playAnimationClip( this.model.get('productInstance')[0].id, val );
        }
        if ( name === 'material') {
            var productId = e.target.parentNode.id;
            var target = e.target.parentNode.getAttribute('target');
            //
            this.view3D.changeMaterial( this.model.get('productInstance')[0].id, target, val );
            //global store for reopen view adn set right radiobutton
            App[ 'productSetting_' + this.model.get('productInstance')[0].id + productId ] = id;
        }
    }, 

    onFlowInfoClick:function( evt ){
        if ( evt.target.matches('.close-popup') ) return;
    
        evt.preventDefault();
        if (this.curPopup)
            Utils.removeClass( this.curPopup, 'is-selected' );
        //
        var popup = evt.currentTarget.querySelector('.popup');
        Utils.addClass( popup, 'is-selected' );
        this.curPopup =  popup;
    },

    onFlowInfoUrlClick:function( evt ){
        var url = evt.currentTarget.getAttribute('href');
        if ( url )
            window.open( url );     
    },

    onFlowInfoCloseClick:function( evt ){
        evt.preventDefault();        
        Utils.removeClass( this.curPopup, 'is-selected' );
        this.curPopup = null;
    },

    onCloseClick:function( evt ){
        evt.preventDefault();
        //
        Backbone.history.navigate( Utils.changeURLpart( window.location.pathname, 3, '', 2 ) , true );
    },

    onLeftClick: function( evt ){
        evt.preventDefault();
        //
        var array = this.model.get('productInstance')[0].products;
        var previous = array[( this.getProductIndex( array ) + array.length-1) % array.length ];
        //
        Backbone.history.navigate( Utils.changeURLpart( window.location.pathname, 4, previous.slug ) , true );
    },

    onRightClick: function( evt ){
        evt.preventDefault();
        //
        var array = this.model.get('productInstance')[0].products;
        var next = array[( this.getProductIndex( array ) + 1 ) % array.length ];
        //
        Backbone.history.navigate( Utils.changeURLpart( window.location.pathname, 4, next.slug ) , true );
    },

    onDocumentsClick: function(evt) {
        var array = this.model.get('productInstance')[0].products;
        var product = array[ this.getProductIndex( array ) ];        
        //
        if ( product.documents )
            App.popUp.show( {   title: 'popup_documents',
                                text: 'popup_documents',
                                button_yes: 'button_close',
                                filelist : product.documents,
                                variable: {} },
                                () => {
                                    
                                });
    },

    onVideoClick:function( evt ){
        evt.preventDefault();
        var button = evt.currentTarget.querySelector('button');
        var target = evt.target.parentNode.getAttribute('target');
        Utils.toggleClass(button, 'paused');
        //
        this.view3D.playVideo( this.model.get('productInstance')[0].id, target, button.getAttribute('value') );
    },

    getProductIndex: function( array ) {
        //get current product index in model array
        var productSlug = Utils.getURLpart( window.location.pathname, 2);        
        this.currIndex = array.findIndex( ( item ) => {
            return item.slug === productSlug;
        });
        return this.currIndex;
    },

    setAnimationToDefault( index ) {
        var array = this.model.get('productInstance')[0].products;
        var product = array[ index !== null ? index : this.getProductIndex( array ) ];
        if ( product.state ) {
            if ( product.state[0].type === 'animation' && product.state[0].state !== this.currAnimation ) {
                 this.view3D.playAnimationClip( this.model.get('productInstance')[0].id, product.state[0].state );
            }
        }

    },

    dispose: function() {
        this.setAnimationToDefault( this.currIndex );
        //
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }
});