var BreadcrumbsModel = require('~/js/model/BreadcrumbsModel'),
    BreadcrumbsCollection = require('~/js/collection/BreadcrumbsCollection'),
    template = require('~/js/template/layout/breadcrumbsTemplate.html'),
    Contentful = require('~/js/Contentful'),    
    App = require("~/js/App"),
    Utils = require('~/js/Utils');

module.exports = Backbone.View.extend({ 

    id: 'breadcrumbs',

    className: '',

    events: {
      "click a": "onBreadcrumbClick"
    },        

    initialize: function () {
    },

    render: function() {
        this.el.innerHTML = template( { collection: this.collection, translate: App.translateCollection } );
        this.show();
        //
        if ( this.collection.models.length <= 2 )
            Utils.setMeta( this.collection.models[0].get('title'), this.collection.models[0].get('desc') === "" ? this.collection.models[0].get('title') : this.collection.models[0].get('desc') );
        //
        return this;
    },

    getData: function ( id, options ) {
        this.collection.url = Contentful.getEntry( id );
        this.collection.fetch( options ).done( ( res ) => {
            if ( res.fields.hasOwnProperty('parent') && res.fields.parent.sys.id )
                this.getData( res.fields.parent.sys.id, {remove: false } );
            else
                this.render();
        });
    },

    set: function( id ) {
        this.collection = new BreadcrumbsCollection();          
        this.getData( id, {reset: true} );
    },

    show: function( id ) {
          Utils.addClass( this.el, 'show' ); 
    },

    hide: function() {
        Utils.removeClass( this.el, 'show' );
    },

    onBreadcrumbClick: function( evt ){
        evt.preventDefault();
        var href = evt.currentTarget.getAttribute('href');
        var url = Backbone.history.getFragment();    
        //    
        Backbone.history.navigate( href === '/' ? '/' + App.HOME_DIR : url.substr(0, url.indexOf( href ) + href.length) , true ); 
    },


    dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }

});
