import '../scss/style.scss';

import 'whatwg-fetch'; //due the IE11 fetch
import './lib/backbone.controller';
//
console.time('App');
import App from "./App";

var LoaderView = require('./view/layout/LoaderView'),
    AssetsCollection = require('~/js/collection/AssetsCollection'),
    LanguageCollection = require('~/js/collection/LanguageCollection'),
    TranslateCollection = require('~/js/collection/TranslateCollection'),
    Contentful = require('./Contentful'),
    Router = require('./Router'),    
    Utils = require('./Utils');

var Playcanvas = require('~/js/pc/Playcanvas').default;

App.eventBus = _.extend({}, Backbone.Events); // Global event aggregator 

//preloader view
App.loader = new LoaderView();
document.body.appendChild( App.loader.el );
App.loader.show();

//some data I need before start the APP, store it to the APP singleton
App.assetsCollection = new AssetsCollection();  
App.languageCollection = new LanguageCollection();     
App.translateCollection = new TranslateCollection();  
//
App.assetsCollection.url = Contentful.getAssets();
App.assetsCollection.fetch().done( () => {
    App.languageCollection.fetch().done( () => {
        App.translateCollection.url = Contentful.getEntries( 'translate' );
        App.translateCollection.fetch().done( () => {
            appInit();
        });
    });
});

function appInit() {
    /*if (window.CSS && CSS.supports('mask-image', 'var(--fake-var)')) {
        alert('ano');
    } else {
      alert('ne');
    } */
    Utils.addClass( document.body, 'mobile-' + Utils.isMobile() );
    Utils.addClass( document.body, 'ie11-' + Utils.isie11() );
    window.addEventListener("resize", onWindowResize, false);      
    onWindowResize();
    //
    App.router = new Router();
    App.pc = new Playcanvas(() => {
        App.loader.hide();
        // HTML5 pushState for URLs without hashbangs
        var hasPushstate = !!(window.history && history.pushState);
        if ( hasPushstate )
            Backbone.history.start({ pushState: true, root: '/' });
        else
            Backbone.history.start();
    });     
};

function onWindowResize() {
    var width = window.innerWidth;
    var height = window.innerHeight;    
    //
    if ( width < 360 ) {
        Utils.toggleClassByPrefix( document.body, 'size-xss', 'size-');
    }        
    else if ( width > 360 && width < 480 ) {
        Utils.toggleClassByPrefix( document.body, 'size-xs', 'size-');
    }
    else if ( width > 480 && width < 544 ) {
        Utils.toggleClassByPrefix( document.body, 'size-sm', 'size-');
    }
    else if ( width > 544 && width < 768) {
        Utils.toggleClassByPrefix( document.body, 'size-md', 'size-');
    }
    else if ( width > 768 && width < 992) {
        Utils.toggleClassByPrefix( document.body, 'size-lg', 'size-');
    }
    else if ( width > 992 && width < 1200) {
        Utils.toggleClassByPrefix( document.body, 'size-xl', 'size-');
    }
    else if ( width > 1200) {
        Utils.toggleClassByPrefix( document.body, 'size-xll', 'size-');
    }
    //
    if( height > width && App.orientation !== 'portrait' ){
        Utils.toggleClassByPrefix( document.body, 'mode-portrait', 'mode-');
        App.orientation = 'portrait';        
        App.eventBus.trigger('orientationChange', 'portrait');
    }
    if ( height < width && App.orientation !== 'landscape' ) {
        Utils.toggleClassByPrefix( document.body, 'mode-landscape', 'mode-');
        App.orientation = 'landscape';  
        App.eventBus.trigger('orientationChange', 'landscape');
    }
}   