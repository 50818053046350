function protoScript( ScriptConstructor ) {
    return function ( app ) {
        var instance = new ScriptConstructor();
        var script;
        //
        if ( app.scripts.has( instance.name ) )
            script = app.scripts.get( instance.name );
        else
            script = pc.createScript( instance.name, app );

        //console.log( "prop", instance.name,  instance); 
        //Add public attributes accessible in the editor
        if (instance.attributes) {
            for (let attr in instance.attributes) {
                script.attributes.add(attr, instance.attributes[attr]);
            };
        }

        // Add intance properties and methods to prototype
        for (let prop in instance) {
            if (prop === 'attributes' || prop === 'name' ) {
                // do nothing
            } else {
                script.prototype[prop] = instance[prop];
            }
        };

        // Add static properties
        for (let prop in ScriptConstructor) {
            script[prop] = ScriptConstructor[prop];
        };

        //add Object.defineProperty
        if (instance.getset) {
            for ( let gs in instance.getset) {
                Object.defineProperty( script.prototype, gs, instance.getset[ gs ] );
            };
        }

        return instance;        
    };
}
export default protoScript;