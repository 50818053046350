import protoScript from "~/js/lib/ProtoScript";

var PosteffectExample = function(){
    this.name = 'posteffectExample';
    //
    this.attributes = {
        "cubeFrom": { type: "asset", assetType: "cubemap", title: "Cube map from" },
        "cubeFromDepth": { type: "asset", assetType: "cubemap", title: "Cube map from zdepth" },
        "cubeTo": { type: "asset", assetType: "cubemap", title: "Cube map To" },
        "cubeToDepth": { type: "asset", assetType: "cubemap", title: "Cube map To depth" },
        'vs': { type: "string", title: 'Vertex Shader' },
        'fs': { type: "string", title: 'Fragment Shader' },
        "lerp": { type: "number", default: 0, min: 0, max: 1, title: "Skydomes crossfade" },
        "scale": { type: "number", default: 0.005, min: 0, title: "Scaling coeficient" },
        "distance": { type: "number", default: 14, min: 0, max: 100, title: "Distance" },
        "distancePassed": { type: "number", default: 0, min: 0, max: 100, title: "Distance passed" },
		"transitionEnabled": { type: "boolean", default: 0, title: "Transition enabled" }
    };
};

PosteffectExample.prototype.initialize = function() {
    this.effect = new pc.PostEffect( this.app.graphicsDevice, this.vs, this.fs, this.entity );
    this.effect.cubeMapFrom = this.cubeFrom ? this.cubeFrom.resource : null;
    this.effect.cubeMapFromDepth = this.cubeFromDepth ? this.cubeFromDepth.resource : null;
    this.effect.cubeMapTo = this.cubeTo ? this.cubeTo.resource : null;
    this.effect.cubeMapToDepth = this.cubeToDepth ? this.cubeToDepth.resource : null;    

    this.effect.lerp = this.lerp;
    this.effect.scale = this.scale;    
    this.effect.distance = this.distance;
    this.effect.distancePassed = this.distancePassed;
    this.effect.transitionEnabled = this.transitionEnabled;

    this.effect.fov = this.entity.camera.camera.fov;    

    this.entity.camera.camera.requestDepthMap();
    
    // add the effect to the camera's postEffects queue
    var queue = this.entity.camera.postEffects;
    queue.addEffect(this.effect);
    
    // when the script is enabled add our effect to the camera's postEffects queue
    this.on('enable', function () { queue.addEffect(this.effect, false); });
    this.on('disable', function () { queue.removeEffect(this.effect); });
};

PosteffectExample.prototype.update = function (dt) {
	this.effect.fov = this.entity.camera.camera.fov;
};

PosteffectExample.prototype.setCubeFrom = function( value ) { this.effect.cubeMapFrom = value; };

PosteffectExample.prototype.setCubeTo = function( value ) { this.effect.cubeMapTo = value; };

PosteffectExample.prototype.doTransition = function( cameraFrom, cameraTo ) {
    var cubeMapFrom = this.effect.cubeMapFrom;
    var cubeMapTo = this.effect.cubeMapTo;
    var cameraRotate = this.app.root.findByName('cameraRotate');
    //
    //shortest way for right rotation
    if ( this.lookCameraCorection ) {
        var diff = this.lookCameraCorection.y - cameraRotate.script.lookCamera.ey;
        var reminder = diff % 360;
        var rotY;
        if (reminder > 180) {
            rotY = cameraRotate.script.lookCamera.ey - (360 - reminder);
        } else if (reminder < -180) {
            rotY = cameraRotate.script.lookCamera.ey + (360 + reminder);
        } else {
            rotY = cameraRotate.script.lookCamera.ey + reminder;
        }
        this.lookCameraCorection.y = rotY;
    }
    //
    this.effect.transitionEnabled = 1;
    this.data = {
        lerp: 0,
        lookCameraX: cameraRotate.script.lookCamera.ex,
        lookCameraY: cameraRotate.script.lookCamera.ey,
        lookCameraFov: cameraRotate.script.lookCamera.fov
    };  
    cameraFrom.setLocalPosition( new pc.Vec3( cameraTo.x, cameraTo.y, cameraTo.z ) );
    //
    return new Promise( (resolve) => { 
        this.app.tween( this.data )
            .to({
                    lerp: 1,
                    lookCameraX: this.lookCameraCorection ? this.lookCameraCorection.x : cameraRotate.script.lookCamera.ex,
                    lookCameraY: this.lookCameraCorection ? this.lookCameraCorection.y : cameraRotate.script.lookCamera.ey,
                    lookCameraFov: this.lookCameraCorection ? this.lookCameraCorection.z : cameraRotate.script.lookCamera.fov
                },0.5, pc.SineInOut)
            .on('update', () => {
                this.effect.lerp = this.data.lerp;
                if ( this.correctionWithTransition ) {
                    cameraRotate.script.lookCamera.setCameraRotation( this.data.lookCameraX, this.data.lookCameraY );
                    cameraRotate.script.lookCamera.setCameraFov( this.data.lookCameraFov );
                }
                //cameraFrom.setLocalPosition( new pc.Vec3( this.data.cameraPosX, this.data.cameraPosY, this.data.cameraPosZ ) );
            })
            .on('complete', () => {
                this.effect.cubeMapFrom = cubeMapTo;
                this.effect.transitionEnabled = 0;  
                this.effect.lerp = 0;              
                this.effect.cubeMapTo = cubeMapFrom;
                this.correctionWithTransition = false;
                resolve();

                this.dataLook = {
                    lookCameraX: cameraRotate.script.lookCamera.ex,
                    lookCameraY: cameraRotate.script.lookCamera.ey,
                    lookCameraFov: cameraRotate.script.lookCamera.fov
                };  
                if ( this.lookCameraCorection )
                    this.app.tween( this.dataLook )
                        .to({
                                lookCameraX: this.lookCameraCorection ? this.lookCameraCorection.x : cameraRotate.script.lookCamera.ex,
                                lookCameraY: this.lookCameraCorection ? this.lookCameraCorection.y : cameraRotate.script.lookCamera.ey,
                                lookCameraFov: this.lookCameraCorection ? this.lookCameraCorection.z : cameraRotate.script.lookCamera.fov,
                            },1, pc.SineInOut)
                        .on('update', () => {
                            cameraRotate.script.lookCamera.setCameraRotation( this.dataLook.lookCameraX, this.dataLook.lookCameraY );
                            cameraRotate.script.lookCamera.setCameraFov( this.dataLook.lookCameraFov );
                        })
                        .on('complete', () => {
                            this.lookCameraCorection = null;

                        })
                        .start();
            })
            .start();
    });
};
PosteffectExample.prototype.setLookCamera = function( correction, correctionWithTransition) {
    this.lookCameraCorection = correction;
    this.correctionWithTransition = correctionWithTransition;
};


export default protoScript( PosteffectExample );
//

