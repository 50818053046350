var Model = require('../model/BuildingModel'),
	Renderer = require('@contentful/rich-text-html-renderer');

module.exports = Backbone.Collection.extend({
        
    model: Model,

	sort_key: 'order',

	comparator: function( item ) {
		return item.get( this.sort_key );
	},     

	parse : function( res ) {
		var data = [];
		
		_.each( res.items, function( res, index ) {
			data.push( new Model({
				title: res.fields.title,
				slug: res.fields.slug,
				enabled: res.fields.enabled,
				order : Number(res.fields.order ),
				desc: res.fields.desc ? Renderer.documentToHtmlString(res.fields.desc) : ''
        	}));
		});
		return data;
    }
});