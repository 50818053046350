var template = require('~/js/template/content/panoTemplate.html'),
    App = require("~/js/App"),
    Utils = require("~/js/Utils");
    

module.exports = Backbone.View.extend({

    id: 'pano',
       
    tagName: 'section',

    events: {
        "click a": "onClick",
        "click .pppProduct.flow-info li": "onFlowInfoClick",
        "click .pppProduct.flow-info a.close-popup": "onFlowInfoCloseClick"
    },

    initialize: function() {

    },

    render: function( model ) {
        this.el.innerHTML = template( { data: model, App } );
        //
        App.breadcrumbs.set( model.get('id') );
        return this;
    },
    
    //handlers
    onClick: function( evt ){
        evt.preventDefault();
        Backbone.history.navigate( evt.currentTarget.pathname, true );
    },

    onFlowInfoClick:function( evt ){
        if ( evt.target.matches('.close-popup') ) return;
    
        evt.preventDefault();
        if (this.curPopup)
            Utils.removeClass( this.curPopup, 'is-selected' );
        //
        var popup = evt.currentTarget.querySelector('.popup');
        Utils.addClass( popup, 'is-selected' );
        this.curPopup =  popup;
    },

    onFlowInfoCloseClick:function( evt ){
        evt.preventDefault();        
        Utils.removeClass( this.curPopup, 'is-selected' );
        this.curPopup = null;
    },

    dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }
});