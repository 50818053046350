var App = require("~/js/App"),
	Utils = require('~/js/Utils');

var PppProduct = require('../pc.script/PppProduct').default;

let instance = null;

class PanoPPP {

	constructor ( app ) {
		this.app = app;		
		//
	    if ( !instance ){
			instance = this;
	    }
		return instance;
	}

	create( pppProducts ) {
		if ( !pppProducts ) return;
			//create root holder
		this.root = new pc.Entity('pppProducts');
		this.app.root.addChild( this.root );
		//
    	pppProducts.forEach( ( product, index ) => {
	        var ppp = new pc.Entity();
	        ppp.name = "ppp" + index;
	        ppp.setPosition( Utils.stringToVec3( product.position ) );
	       	ppp.addComponent('script');	      

	        var pppProduct = new PppProduct( this.app );
	        ppp.script.create( pppProduct.name, { attributes: { 
	        	id : product.id,
	        	rotation: Utils.stringToVec3( product.rotation || '0,0,0' )     	
	        }});		       	
	        this.root.addChild(ppp);
    	});
	}

	dispose() {
		if ( !this.root ) return;
        for (var i = this.root.children.length - 1; i >= 0; --i) {
            if( this.root.children[i] instanceof pc.Entity ){
                this.root.children[i].destroy();
            }
        }
		//dispose root holder		
		this.app.root.removeChild( this.root );
		delete this.root;		
		this.root = null;
	}
  };

export { PanoPPP };