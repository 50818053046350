var BasicController = require('./BasicController'),
	BuildingView = require('../view/content/BuildingView'),
	NotificationView = require('../view/layout/NotificationView'),
 	App = require("~/js/App");

module.exports = BasicController.extend({
	routes: {
        [App.HOME_DIR] : 'setBuilding',
	},

	initialize: function() {
	},

	setBuilding: function() {
		this.view = new BuildingView();
		//this.noteView = new NotificationView();
		document.body.querySelector('main').appendChild( this.view.el );        
		//document.body.insertBefore( this.noteView.el, document.body.querySelector('navbar') );      
	},

    onAnimationEndIn: function() {
 		App.dispose.view.dispose();
    },

    onAnimationEndOut: function() {
    	this.view.dispose();

    },

	remove: function() {
		App.dispose.view = this.view;
		//this.noteView.dispose();
	}
});