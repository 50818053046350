var BasicController = require('./BasicController'),
	PanoModel = require('~/js/model/PanoModel'),
	PanoView = require('../view/content/PanoView'),
	Pano3DView = require('../pc/Pano3DView').default,
	Contentful = require('~/js/Contentful'),
    App = require("~/js/App");


module.exports = BasicController.extend({
	routes: {
		[App.HOME_DIR + ':id/:idpano' ] : 'setPano'
	},

	initialize: function() {
		this.model = new PanoModel();   
	},

	setPano: function( id, idpano ) {
		if ( id === 'product ') return;
		//
		App.eventBus.on('languageChange', this.onLanguageChange, this);   
		//		
		this.view = new PanoView();
		this.view3D = new Pano3DView( App.pc );
		document.body.querySelector('main').appendChild( this.view.el );   
		//
        this.getData( id, idpano, 'onModelFetchedNew' );
	},

	setPanoUpdate: function( id, idpano ) {
		if ( id === 'product ') return;
		//
		this.getData( id, idpano, 'onModelFetchedUpdate' );
	},

	getData: function( id, idpano, callback ) {
		this.id = id;
		this.idpano = idpano;
		this.model.url = Contentful.getEntries( 'pano', 'slug', idpano, 3 );
		this.model.fetch({ id: id }).done( () => { this[ callback ](); });
	},

	onModelFetchedNew: function() {
		this.view.render( this.model );
		this.view3D.init( this.model );
	},

	onModelFetchedUpdate: function() {
		this.view.render( this.model );
		this.view3D.update( this.model );
	},

	onModelFetched2Dupdate: function() {
		this.view.render( this.model );
	},

	onLanguageChange: function() {
		this.getData( this.id, this.idpano, 'onModelFetched2Dupdate' );
	},

	remove: function() {
		App.eventBus.off('languageChange', this.onLanguageChange, this);  
		//
		App.dispose.view = this.view3D;
		this.view.dispose();
	}
});