App = require("~/js/App");

module.exports = {

    isie11: function() {
      return !!window.MSInputMethodContext && !!document.documentMode;
    },

    isMobile: function() {
      var isMobile = false; //initiate as false
      // device detection
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
          || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
          isMobile = true;
      }
      return isMobile;
    },

    getUrlParams: function (search) {
        let hashes = search.slice(search.indexOf('?') + 1).split('&');
        let params = {};
        hashes.map(hash => {
            let [key, val] = hash.split('=');
            params[key] = 1; //decodeURIComponent(val);
        });

        return params;
    },
    stripQueryStringAndHashFromPath: function (url) {
      return url.split("?")[0].split("#")[0];
    },

    //array
    arrFind: function( array, regex ) {
        /* var x = [ "banana", "apple", "orange" ];
        console.log(x.find(/na/)); // Contains 'na'? Outputs: [0]
        console.log(x.find(/a/)); // Contains 'a'? Outputs: [0,1,2]
        console.log(x.find(/^a/)); // Starts with 'a'? Outputs: [0]
        console.log(x.find(/e$/)); // Ends with 'e'? Outputs: [1,2]
        console.log(x.find(/pear/)); // Contains 'pear'? Outputs: [] */
        return array.reduce(function (acc, curr, index, arr) {
          if (regex.test(curr)) { acc.push(index); }
          return acc;
        }, []);
    },

    //transition
    getAnimationEndEventName: function() {
      var transitions = {
        "animation"      : "animationend",
        "OAnimation"     : "oAnimationEnd",
        "MozAnimation"   : "animationend",
        "WebkitAnimation": "webkitAnimationEnd"
       };
      let bodyStyle = document.body.style;
      for(let transition in transitions) {
          if(bodyStyle[transition] != undefined) {
              return transitions[transition];
          } 
      }
    },
    
    supportsTransitions: function() {
      var b = document.body || document.documentElement,
          s = b.style,
          p = 'transition';

      if (typeof s[p] == 'string') { return true; }

      // Tests for vendor specific prop
      var v = ['Moz', 'webkit', 'Webkit', 'Khtml', 'O', 'ms'];
      p = p.charAt(0).toUpperCase() + p.substr(1);

      for (var i=0; i<v.length; i++) {
          if (typeof s[v[i] + p] == 'string') { return true; }
      }

      return false;
    },

    //class
    addClass: function( el, className ) {
        if (el.classList)
          el.classList.add(className);
        else
          el.className += ' ' + className;
    },

    removeClass: function( el, className ) {
        if (el.classList)
          el.classList.remove(className);
        else
          el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
     },

    hasClass: function( el, className ) {
          if (el.classList)
              return el.classList.contains(className);
          else
              return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
     },

    containClass: function( el, className ) {
        return new RegExp('(^| )' + className, 'gi').test(el.className);
     },     

    toggleClass: function( el, className ) {
          if (el.classList) {
            el.classList.toggle(className);
          } else {
            var classes = el.className.split(' ');
            var existingIndex = classes.indexOf(className);

            if (existingIndex >= 0)
              classes.splice(existingIndex, 1);
            else
              classes.push(className);

            el.className = classes.join(' ');
          }
     },

    getClassByPrefix: function(el, prefix) {
        var regx = new RegExp('\\b(\\w*' + prefix + '\\w*)\\b', 'g');
        return el.className.match(regx);
    },

    toggleClassByPrefix: function(el, className, prefix ) {
        var regx = new RegExp('\\b' + prefix + '[^ ]*[ ]?\\b', 'g');
        el.className = el.className.replace(regx, '');
        this.addClass(el, className);
    },

    getDOMClasslist: function( domSearch, domTarget, formElement ) {
        var that = this;
        //
        Array.prototype.forEach.call( domSearch.querySelectorAll( formElement ), function( el, j ){
          if ( el.checked) {
            that.toggleClassByPrefix( domTarget, el.name + '-' + el.id, el.name + '-' );
            //
            if ( el.getAttribute('addToClass') ) {
              var oldClass = that.getClassByPrefix( domTarget, el.getAttribute('addToClass') );
              that.toggleClassByPrefix( domTarget, oldClass + '-' + el.id, el.getAttribute('addToClass') );
            } 
          }
          //
        });
    },    

    //string
    capitalize: function ( string ) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },


    getParameterByName: function(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },

    //literal template fill variable
    fillTemplate: function( templateString, templateVars ) {
        console.log(  );
        if ( this.isie11() )
          return templateString;
        else
          return new Function("return `" + templateString + "`;").call( templateVars );
    },

    //change url segment 
    getURLpart: function( url, index ) {    
        url = url.replace( App.HOME_DIR, '').split("/");
        return url[ index ];
    },

    getURLsegLen: function() {
      return Backbone.history.getFragment().replace( App.HOME_DIR, '').split('/').length;         
    },

    changeURLpart: function( url, index, value, count ) {    
        var _count = count || 1;
        //url window.location.pathname
        url = url.replace(App.HOME_DIR, '').split("/");
        url[ index ] = value; //add id to URL
        if ( value === '' ) url.splice( index, _count );
        return App.HOME_DIR.replace('/','') + url.join("/");
    },

    getUrlExtension: function( url ) {
        // Extension starts after the first dot after the last slash
        var extStart = url.indexOf('.',url.lastIndexOf('/')+1);
        if (extStart==-1) return false;
        var ext = url.substr(extStart+1),
        // end of extension must be one of: end-of-string or question-mark or hash-mark
        extEnd = ext.search(/$|[?#]/);
        return ext.substring (0,extEnd);
    },

    //copy-clone object whithout references, specially backbone models
     deepCopy:function(object) {
        const cache = new WeakMap(); // Map of old - new references

        function copy(obj) {
            if (typeof obj !== 'object' ||
                obj === null ||
                obj instanceof HTMLElement
            )
                return obj; // primitive value or HTMLElement

            if (obj instanceof Date) 
                return new Date().setTime(obj.getTime());

            if (obj instanceof RegExp) 
                return new RegExp(obj.source, obj.flags);

            if (cache.has(obj)) 
                return cache.get(obj);

            const result = obj instanceof Array ? [] : {};

            cache.set(obj, result); // store reference to object before the recursive starts

            if (obj instanceof Array) {
                for(const o of obj) {
                     result.push(copy(o));
                }
                return result;
            }

            const keys = Object.keys(obj); 

            for (const key of keys)
                result[key] = copy(obj[key]);

            return result;
        }

        return copy(object);
    },

    hexToRgb: function (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16) / 255,
        g: parseInt(result[2], 16) / 255,
        b: parseInt(result[3], 16) / 255
      } : null;
    },

    invertHex: function (hex) {
      if (hex.indexOf('#') === 0) {
          hex = hex.slice(1);
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
          hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
          throw new Error('Invalid HEX color.');
      }
      // invert color components
      var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
          g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
          b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
      // pad each with zeros and return
      return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
    },
    
    padZero: function (str, len) {
      len = len || 2;
      var zeros = new Array(len).join('0');
      return (zeros + str).slice(-len);
    },  

    //add script to header
    loadScript: function (src) {
      var head = document.getElementsByTagName('head')[0];
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      return new Promise(function (resolve) {
          script.onload = resolve;
          head.appendChild(script);
      });
    },

    //pc tools
    //vec
    stringToVec2: function( arg ) {
      return new pc.Vec2( arg.split(',').map(Number) );
    },
    stringToVec3: function( arg ) {
      return new pc.Vec3( arg.split(',').map(Number) );
    },

    setMeta: function( title, desc ) {
      title =  title.replace(/<(?:.|\n)*?>/gm, '');
      desc =  desc.replace(/<(?:.|\n)*?>/gm, '').replace(/\s\s+/g, ' ').substring(0,255);

      //console.log("#title", title);
      //console.log("#desc", desc);

      document.title = "2N Virtual Experience" + ( title ? ' - ' + title : '' );
        //
      var meta = document.getElementsByTagName('meta');
      //
      for ( var i = 0, len = meta.length; i < len; i++ ) {
        //description
          if ( meta[ i ].name.toLowerCase() == 'description' && desc ) meta[ i ].content = desc;
     }
    },

    setCookie: function (cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    getCookie: function (cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    },

     encryptBools: function(bools) {
      var trueMultiples = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'A', 'S', 'D'];
      var falseMultiples = ['0', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'z', 'm', 'n', 'b', 'p', 'x', 'c', 'v', 'F', 'G', 'H', 'J', 'K', 'L', 'Z', 'X', 'C', 'V', 'B', 'N', 'M'];
      var str = "",
        run = [];

      for (var i = 0; i < bools.length; i++) {
        if (run.length == 0 || run[run.length - 1] === bools[i]) {
          //stack up successive trues or successive falses as a "run"
          run.push(bools[i]);
        } else {
          //when the run ends, convert it to a trueMultiples or falseMultiples character
          var encryptionSet = bools[i] ? falseMultiples : trueMultiples;
          while (run.length > encryptionSet.length) {
            //if it's too long to be a single character, use multiple characters
            str += encryptionSet[encryptionSet.length - 1];
            run = run.slice(0, run.length - encryptionSet.length);
          }
          str += encryptionSet[run.length - 1];
          run = [bools[i]];
        }
      }

      if (bools.length > 0) {
        //for the last run, convert it to a trueMultiples or falseMultiples character
        var encryptionSet = run[run.length - 1] ? trueMultiples : falseMultiples;
        while (run.length > encryptionSet.length) {
          //if it's too long to be a single character, use multiple characters
          str += encryptionSet[encryptionSet.length - 1];
          run = run.slice(0, run.length - encryptionSet.length);
        }
        str += encryptionSet[run.length - 1];
      }

      return str;
    },

    decryptBools: function (str) {
      var trueMultiples = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'A', 'S', 'D'];
      var falseMultiples = ['0', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'z', 'm', 'n', 'b', 'p', 'x', 'c', 'v', 'F', 'G', 'H', 'J', 'K', 'L', 'Z', 'X', 'C', 'V', 'B', 'N', 'M'];      
      var bools = [];

      for (var i = 0; i < str.length; i++) {
        if (trueMultiples.indexOf(str[i]) > -1) {
          for (var j = 0; j <= trueMultiples.indexOf(str[i]); j++) {
            bools.push(true);
          }
        } else if (falseMultiples.indexOf(str[i]) > -1) {
          for (var j = 0; j <= falseMultiples.indexOf(str[i]); j++) {
            bools.push(false);
          }
        }
      }

      return bools;
    }
};