var LayoutView = require('./view/LayoutView');

var BuildingController = require('./controller/BuildingController'),
    SolutionController = require('./controller/SolutionController'),
    PanoController = require('./controller/PanoController'),
    ProductInstanceController = require('./controller/ProductInstanceController'),
    ProductController = require('./controller/ProductController');

module.exports = Backbone.Router.extend({
    controllers: {},

    initialize: function( options ){
        this.controllers.building = new BuildingController({router: this});          
        this.controllers.solution = new SolutionController({router: this});
        this.controllers.pano = new PanoController({router: this});        
        this.controllers.productInstance = new ProductInstanceController({router: this});
        this.controllers.product = new ProductController({router: this});
        //
        if (!this.layoutView){
            this.layoutView = new LayoutView({});
            this.layoutView.render();
        }
        //Backbone.history.on('route', this.historyChange, this );    
    },

    routes: {
        '*notFound'                             : 'notFound'
    },

    historyChange: function( event ) {
        var url = Backbone.history.getFragment();           
        var host = Backbone.history.location.host;
    }
});