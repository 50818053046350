var template = require('~/js/template/layout/loaderTemplate.html'),
    App = require("~/js/App"),
    Utils = require('~/js/Utils');

module.exports = Backbone.View.extend({ 

    id: 'loader',

    className: 'loader-overlay',

    initialize: function () {
        this.render();
    },

    render: function() {
        this.el.innerHTML = template( {} );
        return this;
    },


    show: function( data, callback ) {
        Utils.addClass( this.el, 'show' );
    },

    hide: function() {
        Utils.removeClass( this.el, 'show' );

    },

    dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }

});
