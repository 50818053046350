module.exports = Backbone.Model.extend({
    defaults: {
    	id: '',
    	order : 0,
    	title : {},
    	slug : '',
    	parent : '',    	
    	reference : '',
    	picture : '',
	}
});