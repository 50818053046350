var App = require("~/js/App"),
	Utils = require('~/js/Utils');

var LoadModel = require('../pc.script/LoadModel').default,
	ElementToEntity = require('../pc.script/ElementToEntity').default,
	Material = require('../pc.script/Material').default,
	Video = require('../pc.script/Video').default;

let instance = null;

class PanoModels {

	constructor ( app ) {
		this.app = app;
		this._root;
		//
	    if ( !instance ){
			instance = this;
			//
		    //create holder form models, never dispose it
			this.root = new pc.Entity('models');
			this.app.root.addChild( this.root );	
	    }
		return instance;
	}

	get root() {
		return this._root;
	}

	set root( value ) {
	  this._root = value ;
	}

	create( productsInPano, enableDisable ) {
		var enableDisable = typeof enableDisable === 'undefined' ? true : enableDisable;
		//false - only preloading  -> no enablig 

		return new Promise( (resolve) => {
			//get entity which to be dissabled
			if ( enableDisable ) {
				const results = this.root.children.filter(({ name: id1 }) => !productsInPano.some(({ id: id2 }) => id2 === id1));
				results.forEach( ( entityChildren ) => {
					entityChildren.enabled = false;
				});
			}

			async function asyncForEach( that, arr) {
				for (const productInstance of arr) {
					if ( that.root.findByName(  productInstance.id ) ) {
						//instance uz existuje
						if ( enableDisable ) {
							var modelInstance = that.root.findByName(  productInstance.id );
							modelInstance.enabled = true;
							//set interactivity
							modelInstance.children.forEach( ( child ) => {
								child.script.elementToEntity.setInput( productInstance.interactive );
							});
						}
					} else {
						//musim ji zalozit
						var modelInstance = new pc.Entity();
					    var elementToEntity = new ElementToEntity( that.app );
					    //				
						modelInstance.name = productInstance.id;
				       	modelInstance.setPosition( Utils.stringToVec3( productInstance.position ) );
				       	if ( productInstance.rotation ) {
				       		modelInstance.setLocalEulerAngles( Utils.stringToVec3( productInstance.rotation ) );
				       	}
    					modelInstance.setLocalScale( productInstance.scale );
    					//
				       	that.root.addChild( modelInstance );

				       	var productSlug = Utils.getURLpart( window.location.pathname, 4); 
				       	//
						async function asyncForEach2( that, array) {
						  for (const product of array) {
						  	var index = array.findIndex(x => x === product );
						  	//
							var model = new pc.Entity();
							model.name = product.slug;
					       	model.addComponent('script');	
							var loadModel = new LoadModel( that.app );
							var material = new Material( that.app );							
							var video = new Video( that.app );							
				        	model.script.create( loadModel.name, { attributes: { scale: 0.01, disableHiding: productInstance.disableHiding }});
					    	model.script.create( elementToEntity.name, { attributes: { id:productInstance.id, interactive: productInstance.interactive, productInstanceSlug: productInstance.slug, productSlug: product.slug }});
				        	model.script.create( material.name, { attributes: {}});		
							model.script.create( video.name, { attributes: {}});	
					       	modelInstance.addChild( model );
					       	//
					       	model.enabled = ( ( index === 0 && !productSlug ) || productSlug === product.slug );
					       	//
					       	await model.script.loadModel.load( product.modelLink );
						  }
						}
						await asyncForEach2( that, productInstance.products );
						if ( !enableDisable ) modelInstance.enabled = false;
					}
	        	};
	        	resolve();
	        };
			asyncForEach( this, productsInPano );
    	});
	}

	dispose( ignoredEntity ) {
		var models = this.root.findByName("models");
		this.entities = [];		
		this.#getEntity( models, ignoredEntity );

		this.entities.splice(1, this.entities.length).forEach( ( entity ) => {
			if ( typeof entity.destroy === 'function' ){
				entity.destroy();
			}
		});
	}

	#getEntity = (entity, ignoredEntity ) => {
	    if (entity === undefined || entity.name === ignoredEntity )
	        return;
	    this.entities.push(entity);
	    var i;
	    for (i = 0; i < entity.children.length; ++i) {
	        if ( entity.children[i].name !== 'RootNode' ){
	        	this.level++;
	            this.#getEntity(entity.children[i], ignoredEntity);
	        }
	       	this.level--;
	    }
	};

  };

export { PanoModels };