module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+=' ';
 function menuBuilder(data, urlPart) {
      for (var i = 0; i < data.length; i++) { 
__p+='\r\n          <li>\r\n              <input type="radio" name="accordion" id="'+
((__t=( data[i].id ))==null?'':__t)+
'" ';
 if ( i === 0 ) { 
__p+='checked';
 } 
__p+='>\r\n              <label for="'+
((__t=( data[i].id ))==null?'':__t)+
'">';
 if ( data[i].picture !== null ) { 
__p+='<img src="'+
((__t=( data[i].picture.get('file') ))==null?'':__t)+
'" alt="'+
((__t=( data[i].title ))==null?'':__t)+
'">';
 } 
__p+=''+
((__t=( data[i].title ))==null?'':__t)+
'</label>              \r\n\r\n          ';
 if (data[i].childrens.length !== 0) { 
__p+='\r\n                  <div class="content">\r\n                      <ul>\r\n                        ';
 _.each( data[i].childrens, function( item, index ) { 
__p+='  \r\n                         <li><a href="/'+
((__t=( App.HOME_DIR + item.slug + '/' + item.reference ))==null?'':__t)+
'" ';
 if ( urlPart === item.reference ) { 
__p+='class="selected"';
 } 
__p+='>';
 if ( item.picture !== null ) { 
__p+='<img src="'+
((__t=( item.picture.get('file') ))==null?'':__t)+
'" alt="'+
((__t=( item.title ))==null?'':__t)+
'">';
 } 
__p+=''+
((__t=( item.title ))==null?'':__t)+
'</a></li>\r\n                        ';
 }); 
__p+='  \r\n                      </ul>\r\n                  </div>\r\n          ';
 } 
__p+='\r\n          </li>\r\n      ';
 }
  } 
__p+='  \r\n\r\n<form>\r\n  <div class="tabs">\r\n    <div class="tab">\r\n      <input type="radio" name="sidemenu" id="products" value="products" checked class="tab-switch">\r\n      <label for="products">'+
((__t=( translate.getText( 'products', 'shortText' ) ))==null?'':__t)+
'</label>\r\n      <div class="tab-content">\r\n          <ul class="accordion">\r\n          ';
 menuBuilder( data, Utils.getURLpart(window.location.pathname, 2) ); 
__p+='\r\n          </ul>\r\n      </div>\r\n    </div>\r\n    <div class="tab">\r\n      <input type="radio" name="sidemenu" id="language" value="language" class="tab-switch">\r\n      <label for="language">'+
((__t=( lang ))==null?'':__t)+
'</label>\r\n      <div class="tab-content">\r\n          <ul class="language">\r\n              ';
 _.each( dataLng, function( data, index ) { 
__p+='     \r\n                    <li ';
 if ( lang == data.get('code') ) { 
__p+='class="selected"';
 } 
__p+=' value="'+
((__t=( data.get('code') ))==null?'':__t)+
'" currency="'+
((__t=( data.get('currency') ))==null?'':__t)+
'" ><a href="#"><img src="/public/img/flags/'+
((__t=( data.get('code') ))==null?'':__t)+
'.png" alt="'+
((__t=( data.get('code') ))==null?'':__t)+
'">'+
((__t=( data.get('name') ))==null?'':__t)+
'</a></li>\r\n              ';
 }); 
__p+='      \r\n          </ul>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</form>\r\n';
 if ( App.COOKIE_BAR ) { 
__p+='\r\n<div class="cookie-set"><svg><use xlink:href="#icon-cookie"></use></svg><span>Cookie</span></div>\r\n';
 } 
__p+='';
}
return __p;
};
