var Model = require('../model/BreadcrumbsModel'),
	Renderer = require('@contentful/rich-text-html-renderer');

module.exports = Backbone.Collection.extend({
        
    model: Model,

	parse : function( res ) {
		var data = {};

		data.title = res.fields.title;
		data.desc = Renderer.documentToHtmlString( res.fields.desc );
		data.slug = res.fields.slug;
		data.parent = res.fields.parent ? res.fields.parent.sys.id : null;

		return data;
    }

});