module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="bar">\r\n   <h1><svg><use xlink:href="#icon-cookie"></use></svg>'+
((__t=( translate.getText( 'cookie_bar_info', 'shortText' ) ))==null?'':__t)+
'</h1>\r\n   '+
((__t=( translate.getText( 'cookie_bar_info', 'longText' ) ))==null?'':__t)+
'\r\n   <div class="actions">\r\n      <div class="btn set"><button type=\'button\' class=\'text border\'>'+
((__t=( translate.getText( 'cookie_button_set', 'shortText' ) ))==null?'':__t)+
'</button></div>\r\n      <div class="btn ok"><button type=\'button\' class=\'text\'> OK </button></div>\r\n   </div>\r\n   <div class="decline">'+
((__t=( translate.getText( 'cookie_bar_decline', 'longText' ) ))==null?'':__t)+
'</div>\r\n</div>\r\n<div class="overlay-set">\r\n   <div class="cookie-set">\r\n      <h1>'+
((__t=( translate.getText( 'cookie_set_info', 'shortText' ) ))==null?'':__t)+
'</h1>\r\n      '+
((__t=( translate.getText( 'cookie_set_info', 'longText' ) ))==null?'':__t)+
'\r\n\r\n      <div class="accordion-list">\r\n         <button class="accordion">\r\n            <div class="icon">\r\n               <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">\r\n                  <path d="M14 5.83331V22.1666" stroke="#273240" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>\r\n                  <path d="M5.83337 14H22.1667" stroke="#273240" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>\r\n               </svg>\r\n            </div>\r\n            <h2>'+
((__t=( translate.getText( 'cookie_set_00', 'shortText' ) ))==null?'':__t)+
'</h2>\r\n            <label class="switch">\r\n               <input type="checkbox" checked disabled>\r\n               <span class="slider round"></span>\r\n            </label>\r\n         </button>\r\n         <div class="panel">\r\n           '+
((__t=( translate.getText( 'cookie_set_00', 'longText' ) ))==null?'':__t)+
'\r\n         </div>\r\n\r\n         <button class="accordion">\r\n            <div class="icon">\r\n               <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">\r\n                  <path d="M14 5.83331V22.1666" stroke="#273240" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>\r\n                  <path d="M5.83337 14H22.1667" stroke="#273240" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>\r\n               </svg>\r\n            </div>\r\n            <h2>'+
((__t=( translate.getText( 'cookie_set_01', 'shortText' ) ))==null?'':__t)+
'</h2>\r\n            <label class="switch">\r\n               <input type="checkbox" name="analytics_storage" id="1" '+
((__t=( ( bools[1] ? "checked" : "" ) ))==null?'':__t)+
'>\r\n               <span class="slider round"></span>\r\n            </label>\r\n         </button>\r\n         <div class="panel">\r\n           '+
((__t=( translate.getText( 'cookie_set_01', 'longText' ) ))==null?'':__t)+
'\r\n         </div>\r\n\r\n         <button class="accordion">\r\n            <div class="icon">\r\n               <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">\r\n                  <path d="M14 5.83331V22.1666" stroke="#273240" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>\r\n                  <path d="M5.83337 14H22.1667" stroke="#273240" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>\r\n               </svg>\r\n            </div>\r\n            <h2>'+
((__t=( translate.getText( 'cookie_set_02', 'shortText' ) ))==null?'':__t)+
'</h2>\r\n            <label class="switch">\r\n               <input type="checkbox" name="personalization_storage" id="2" '+
((__t=( ( bools[2] ? "checked" : "" ) ))==null?'':__t)+
'>\r\n               <span class="slider round"></span>\r\n            </label>\r\n         </button>\r\n         <div class="panel">\r\n           '+
((__t=( translate.getText( 'cookie_set_02', 'longText' ) ))==null?'':__t)+
'\r\n         </div>\r\n\r\n         <button class="accordion">\r\n            <div class="icon">\r\n               <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">\r\n                  <path d="M14 5.83331V22.1666" stroke="#273240" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>\r\n                  <path d="M5.83337 14H22.1667" stroke="#273240" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>\r\n               </svg>\r\n            </div>\r\n            <h2>'+
((__t=( translate.getText( 'cookie_set_03', 'shortText' ) ))==null?'':__t)+
'</h2>\r\n            <label class="switch">\r\n               <input type="checkbox" name="ad_storage" id="3" '+
((__t=( ( bools[3] ? "checked" : "" ) ))==null?'':__t)+
'>\r\n               <span class="slider round"></span>\r\n            </label>\r\n         </button>\r\n         <div class="panel">\r\n           '+
((__t=( translate.getText( 'cookie_set_03', 'longText' ) ))==null?'':__t)+
'\r\n         </div>         \r\n      </div>\r\n\r\n      <div class="confirm-btn"><button type=\'button\' class=\'yes text border\'>'+
((__t=( translate.getText( 'cookie_set_button', 'shortText' ) ))==null?'':__t)+
'</button></div>\r\n\r\n      <div class="exit-btn">\r\n         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\r\n            <g opacity="0.5">\r\n               <path d="M18 6L6 18" stroke="#273240" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\r\n               <path d="M6 6L18 18" stroke="#273240" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\r\n            </g>\r\n         </svg>\r\n      </div>\r\n   </div>\r\n</div>';
}
return __p;
};
