import protoScript from "~/js/lib/ProtoScript";
var Utils = require('~/js/Utils');

var ElementToEntity = function(){
    this.name = 'elementToEntity';
    //
    this.attributes = {
        'id'   : { type: "string", default:"" },        
        'interactive'   : { type: "boolean", default:"" },          
        'productInstanceSlug'   : { type: "string", default:"" },
        'productSlug'           : { type: "string", default:"" }
    };
};

ElementToEntity.prototype.initialize = function() {
    this.color = '#ff000000';	
    this.element = new pc.Entity('models');
    this.element.name = 'element';
    this.element.rotateLocal(0, 180, 0);
    this.entity.addChild( this.element );
    //
    this.camera = this.app.root.findByName('camera');
    this.alpha = -1.0;
    this.inertiaFactor = 0.01;    
    this.targetX = 0;
    this.targetY = 0;
};
ElementToEntity.prototype.update = function( dt ) {
    var t = this.inertiaFactor === 0 ? 1 : Math.min(dt / this.inertiaFactor, 1);  
    var element = document.getElementById( this.id );
    //
    if ( element ) {
        this.alpha += ( Math.min( 1.0, this.element.forward.dot( this.camera.forward ) * 3 ) - this.alpha ) * 0.1;
        var point = this.camera.camera.worldToScreen( this.element.getPosition() );
        this.targetX = pc.math.lerp(this.targetX, point.x, t);
        this.targetY = pc.math.lerp(this.targetY, point.y, t);
        //
        element.style.top = this.targetY + "px"; //(this.popupPosition.y - this.popup.offsetHeight - 70) + "px";
        element.style.left = this.targetX + "px";
        element.style.opacity = this.alpha;
        //
    }   
};

//
ElementToEntity.prototype.addElement = function( entity ) {
    //
	this.element.addComponent('element', {
	    type: 'image',
	    preset: "Center",
	    anchor: [0.5,1,0.5,1],
	    pivot: [0.5, 0.5],
	    width: entity.dimensions.x,
	    height: entity.dimensions.y,
	    color: new pc.Color().fromString( this.color ),
	    opacity: 0
	});
     this.element.addComponent('button', {
        active: true,
        transitionMode: pc.BUTTON_TRANSITION_MODE_TINT,
        imageEntity: this.element,
        hoverTint: new pc.Color().fromString( this.color ),
        pressedTint: new pc.Color(0.5, 0.5, 0.5, 0),
        inactiveTint: new pc.Color().fromString( this.color ),
        fadeDuration: 1
    });
    this.element.element.useInput = this.interactive;
     //
    this.element.element.on('click', this.onEntityClick, this);   
    
    this.element.element.on('mouseenter', this.onEntityEnter, this);
    this.element.element.on('mouseleave', this.onEntityLeave, this);
};

ElementToEntity.prototype.setInput = function ( val ) {
    this.element.element.useInput = val;
};


//handlers
ElementToEntity.prototype.onEntityEnter = function ( evt ) {
    document.body.style.cursor = "pointer";
};
ElementToEntity.prototype.onEntityLeave = function ( evt ) {
    document.body.style.cursor = "auto";
};
ElementToEntity.prototype.onEntityClick = function ( evt ) {
    //disable click when URL not have specific lenght, maybe improve it
    if ( Backbone.history.location.pathname.replace(App.HOME_DIR, '').split('/').length === 3 )
	   Backbone.history.navigate( Utils.changeURLpart( window.location.pathname, 3, this.productInstanceSlug + '/' + this.productSlug ) , true );
};

export default protoScript( ElementToEntity );
//