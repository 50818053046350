import protoScript from "~/js/lib/ProtoScript";
import App from '~/js/App';

var Video = function(){
    this.name = 'video';
};

Video.prototype.initialize = function() {
	this.playState = false;
	//
	this.video = document.getElementById( 'video' );
	//	
    this.on('enable', this.onEnable, this);
    this.on('disable', this.onDisable, this);  
};
//handlers
Video.prototype.onEnable = function() {

};
Video.prototype.onDisable = function() {
	this.playState = false;
	this.video.pause();
    this.video.currentTime = 0;
    //restore diffuse
    if ( this.materials ) {
	    for (var i = 0; i < this.materials.length; i++) {
	    	var material = this.materials[i];
	    	//
	    	material.diffuseMap = this.diffuseBak;
	    	material.diffuseMapChannel = 'rgb';
	    	material.update();
	    }
    }
};
Video.prototype.update = function( dt ) {
	if ( this.videoTexture )
		this.videoTexture.upload();
};

Video.prototype.play = function( target, file ) {
	this.playState = !this.playState;
	//
	if ( this.playState ) {
		if ( !this.videoTexture ) {
			this.initVideo( target );
		}
    	this.video.src = App.ASSETS + 'video/' + file;
    	this.video.load();		
	} else {
		this.video.pause();
        this.video.currentTime = 0;
	}
};

Video.prototype.initVideo = function( target ) {
	var targets = target.split('|');
	var entity = this.entity.children.filter( child => {  return child.name === "gltf"; })[0];
	this.materials = [];
	//
    for (var j = 0; j < targets.length; j++) {
		var nodeName = targets[j].split(',')[0];
		var materialName = targets[j].split(',')[1];
		//filter meshinstance by name and material
		var meshInstances = entity.model.meshInstances.filter( mesh => { return mesh.node.name === nodeName && mesh.material.name === materialName; });
	    for (var i = 0; i < meshInstances.length; i++) {
	    	this.materials.push( meshInstances[i].material );
	    }
	}
	//bak diffuse
	if ( !this.diffuseBak && this.materials.length ) {
		this.diffuseBak = this.materials[0].diffuseMap;
	}

	//
	this.videoTexture = new pc.Texture( this.app.graphicsDevice, {
		flipY: false,
        format: pc.PIXELFORMAT_R5_G6_B5,
        minFilter: pc.FILTER_LINEAR_MIPMAP_LINEAR,
        magFilter: pc.FILTER_LINEAR,
        //addressU: pc.ADDRESS_CLAMP_TO_EDGE,
        //addressV: pc.ADDRESS_CLAMP_TO_EDGE,
        mipmaps: true
    });
    this.videoTexture.setSource(this.video);

	this.video.addEventListener('canplaythrough', function (e) {
		if ( this.playState ) {
	        //
		    for (var i = 0; i < this.materials.length; i++) {
		    	var material = this.materials[i];
		    	//
		    	material.diffuseMap = this.videoTexture;
		    	//material.diffuseMapChannel = 'rgb';
		    	console.log( material );
		    	material.update();
		    }
	        this.video.play();
		}

    }.bind(this));

};

export default protoScript( Video );
//