var Contentful = require('~/js/Contentful'),
    Utils = require('~/js/Utils');

module.exports = Backbone.Model.extend({
    defaults: {
    	title: '',
    	slug: '',
    	sphereLink: '',
    	cameraPosition: '',
    	cameraRotation: '0,0',
    	cameraFov: 60,
    	directions: [],
    	productsInPano: []
	},

    fetch: function(options) {
    	this.parentSlug = options.id;
        return Backbone.Model.prototype.fetch.call(this, options);
    },

	parse : function( res ) {
		//console.log(res);
		//reset model
		this.clear().set( this.defaults );
		//
		var entry = res.includes ? res.includes.Entry : null;	
		//filter data by parent url
		res.items = res.items.filter( item => { return Contentful.getInclude( entry, item.fields.parent.sys.id )[0].fields.slug === this.parentSlug; });
		//fake direction if exist
		var directionPosition = res.items[0].fields.directionPosition ? res.items[0].fields.directionPosition.split('|') : null;
		//fake direction correction
		var directionCorrection = res.items[0].fields.directionCorrection ? res.items[0].fields.directionCorrection.split('|') : null;
		//
		if ( res.items[0].fields.direction ) {
			var directions = [];			
			//directions
			res.items[0].fields.direction.forEach( ( res, index ) => {
				var direction = {};
				direction.id = res.sys.id;
				direction.title = Contentful.getInclude( entry, res.sys.id )[0].fields.title;
				direction.slug = Contentful.getInclude( entry, res.sys.id )[0].fields.slug;
				direction.cameraPosition = Contentful.getInclude( entry, res.sys.id )[0].fields.cameraPosition;
				direction.sphereLink = Contentful.getInclude( entry, res.sys.id )[0].fields.sphereLink;
				//fake direction position
				if ( directionPosition && directionPosition[index] && directionPosition[index] !== '0' )
					direction.cameraPosition = directionPosition[index];
				//fake direction correction
				if ( directionCorrection && directionCorrection[index] && directionCorrection[index] !== '0' ) {
					var temp = directionCorrection[index].split(',').map(Number);
					direction.correction = new pc.Vec3( temp[0],temp[1],temp[2] );
					if ( temp.length === 4 )
						direction.correctionWithTransition = true;
				}
				//for preloading
				var productsInPano = Contentful.getInclude( entry, res.sys.id )[0].fields.productsInPano;
				var productsInteractive =  Contentful.getInclude( entry, res.sys.id )[0].fields.productsInteractive;
				direction.productsInPano = productsInPano ? this.getProductsInPano( Contentful.getInclude( entry, productsInPano[0].sys.id ), entry, productsInteractive ) : [];
				//
				directions.push( direction );
			});
			this.set('directions', directions, {silent: true} );
		};
		//positions for ppp
		var pppPosition = res.items[0].fields.pppPosition ? res.items[0].fields.pppPosition.split('|') : null;
		//rotations for ppp
		var pppRotation = res.items[0].fields.pppRotation ? res.items[0].fields.pppRotation.split('|') : null;
		//
		if ( res.items[0].fields.pppProduct ) {
			var pppProducts = [];			
			//
			res.items[0].fields.pppProduct.forEach( ( res, index ) => {
				var pppProduct = {};
				pppProduct.id = res.sys.id;
				pppProduct.title = Contentful.getInclude( entry, res.sys.id )[0].fields.title;
				pppProduct.url = Contentful.getInclude( entry, res.sys.id )[0].fields.url;
				//position for products
				if ( pppPosition && pppPosition[index] && pppPosition[index] !== '0' )
					pppProduct.position = pppPosition[index];
				//rotation for products
				if ( pppRotation && pppRotation[index] && pppRotation[index] !== '0' )
					pppProduct.rotation = pppRotation[index];

				pppProducts.push( pppProduct );
			});
			this.set('pppProducts', pppProducts, {silent: true} );
		};
		//
		if ( res.items[0].fields.productsInPano ) {
			this.set('productsInPano', this.getProductsInPano(res.items[0].fields.productsInPano, entry, res.items[0].fields.productsInteractive.split(',')), {silent: true} );
		};
		//
        this.set('id', res.items[0].sys.id, {silent: true} );        		
		this.set('title', res.items[0].fields.title, {silent: true} );
		this.set('cameraPosition', res.items[0].fields.cameraPosition, {silent: true} );
		this.set('cameraFov', res.items[0].fields.cameraFov, {silent: true} );		
		this.set('cameraRotation', res.items[0].fields.hasOwnProperty('cameraRotation') ? Utils.stringToVec2( res.items[0].fields.cameraRotation ) : Utils.stringToVec2('0,0'), {silent: true} );		
		this.set('sphereLink', res.items[0].fields.sphereLink );
		//
		//console.log( this );
    },


    getProductsInPano : function( data, entry, interactive ) {
    	if ( data.length !== interactive.length ) console.log('nesouhlasi delka poli!!');
    	if ( !data ) return [];
		var productsInPano = [];    	
		//
		data.forEach( ( res, index ) => {
			var productInPano = {};
			productInPano.id = res.sys.id;
			productInPano.slug = Contentful.getInclude( entry, res.sys.id )[0].fields.slug;
			productInPano.title = Contentful.getInclude( entry, res.sys.id )[0].fields.title;
			productInPano.position = Contentful.getInclude( entry, res.sys.id )[0].fields.position;
			productInPano.rotation = Contentful.getInclude( entry, res.sys.id )[0].fields.rotation;
			productInPano.scale = Contentful.getInclude( entry, res.sys.id )[0].fields.scale ? Utils.stringToVec3( Contentful.getInclude( entry, res.sys.id )[0].fields.scale ) : Utils.stringToVec3('1,1,1');
			productInPano.products = Contentful.getInclude( entry, res.sys.id )[0].fields.products;
			productInPano.interactive = Boolean(Number(interactive[index]));
			if ( productInPano.products ) {
				//3d models in instance
				var products = [];
				productInPano.products.forEach( ( product ) => {
					var obj = {};
					obj.slug = Contentful.getInclude( entry, product.sys.id )[0].fields.slug;
					obj.title = Contentful.getInclude( entry, product.sys.id )[0].fields.title;
					obj.desc = Contentful.getInclude( entry, product.sys.id )[0].fields.desc;
					obj.modelLink = Contentful.getInclude( entry, product.sys.id )[0].fields.modelLink;
					//obj.animation = Contentful.getInclude( entry, product.sys.id )[0].fields.animation;
					products.push( obj );
				});
				productInPano.products = products;
			}
			//
			productsInPano.push( productInPano );
		});
		//
		return productsInPano;
    }

});