module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="align-start">\r\n	<a href="" class="logo"><svg><use xlink:href="#2n-logo"></use></svg></a>\r\n	<span class="title">Virtual Experience</span>\r\n</div>\r\n\r\n<div class="align-end">\r\n	';
 if ( App.LANGUAGE === 'en-US' ) { 
__p+='\r\n	<a class="" href="https://go.2n.com/contact-form?utm_source=virtual-experience" target="_blank">Contact Us</a>\r\n	';
 }else { 
__p+='\r\n	<a class="" href="https://go.2n.com/kontaktni-formular?utm_source=virtual-experience" target="_blank">Kontaktujte nás</a>\r\n	';
 } 
__p+='\r\n	<a class="" href="https://www.2n.com" target="_blank">www.2n.com</a>\r\n	\r\n	<input class="menu-btn" type="checkbox" id="menu-btn" />\r\n	<label class="menu-icon" for="menu-btn"><span class="title">Menu</span><span class="navicon"></span></label>\r\n</div>';
}
return __p;
};
