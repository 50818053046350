var NavbarView = require('./layout/NavbarView'),
    ContentView = require('./layout/ContentView'),
    SideMenuView = require('./layout/SideMenuView'),
    PopupView = require('./layout/PopupView'),
    BreadcrumbsView = require('./layout/BreadcrumbsView'),
    CookieView = require('./layout/CookieView'),    
    App = require("~/js/App");

module.exports = Backbone.View.extend({ 

    initialize: function () {
    },

    render: function () {
        this.navbarView = new NavbarView();
        document.body.appendChild( this.navbarView.el );

        this.contentView = new ContentView();
        document.body.appendChild( this.contentView.el );  

        this.sideMenuView = new SideMenuView();
        document.body.appendChild( this.sideMenuView.el );        

        App.popUp = new PopupView();
        document.body.appendChild( App.popUp.el );

        App.breadcrumbs = new BreadcrumbsView();
         document.body.appendChild( App.breadcrumbs.el ); 

         if ( App.COOKIE_BAR ) {
            App.cookie = new CookieView();
            document.body.appendChild( App.cookie.el );    
         }
        return this;
    },

    update: function( obj ) {},
 
    dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }

});
