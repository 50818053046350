var App = require("~/js/App"),
	Utils = require("~/js/Utils");

class CubeMap {

	#pSuffix = ['r','l','u','d','f','b']
	#disposeAssets = [];

	constructor ( app ) {
	    this.app = app;
	}

	load( pano, change ) {
		return new Promise( (resolve) => {
			let findLoadedCube = this.app.assets._assets.find(o => o.name === pano );
			if ( findLoadedCube ) {
				this.assetAdd( findLoadedCube );
				resolve( findLoadedCube.resource );
			} else 
				this.#setSides( pano, change )
					.then( sides => this.#setCube( pano, sides) )
						.then( cube => resolve( cube ) );
		});
	}

	assetAdd( asset ) {
	    this.#disposeAssets.push( asset );
	}

	assetRemove( asset ) {
	    this.#disposeAssets = this.#disposeAssets.filter( disposeAsset => { return disposeAsset.name !== asset.name; });
	}

	cleanUnused( names ) {
		//names - array preserve in assets, other dispose
		const unusedCubemap = this.#disposeAssets.filter( asset => { return asset.type === "cubemap"; }).filter(({ name: id1 }) => !names.some((id2) => id2 === id1 ));
		unusedCubemap.forEach( ( cubemap ) => {
			cubemap._levelsEvents.forEach( ( texture ) => {
				this.app.assets.remove( texture );
				texture.unload();
			});
			this.app.assets.remove( cubemap );
			cubemap.unload();
		});
		//debug
		//console.log("__________" );
		//this.#disposeAssets.forEach( ( asset ) => {	console.log("zbyle assety", asset.name ); });
	}

	unloadAll() {
		console.log('uloadALL', this.#disposeAssets);
		this.#disposeAssets.forEach( ( cubemap ) => {
			cubemap._levelsEvents.forEach( ( texture ) => {
				this.app.assets.remove( texture );
				texture.unload();
			});
			this.app.assets.remove( cubemap );
			cubemap.unload();
		});
	}

    #setSides = (pano, change) => {
		return new Promise( (resolve) => {
			var assetCount = 0;
			var textures = [];
			let changePrefix;
			//
			if ( change )
				changePrefix = ( change !== null ? change.split(':') : null );					
			//
			this.#pSuffix.forEach( ( suffix, i ) => {
    			//change suffix by the change
				if ( changePrefix && suffix === changePrefix[0] )
					suffix += changePrefix[1];
    			//
				var texture = App.ASSETS + 'pano/' + pano + ( Utils.isMobile() || !wasmSupported() ? '/1024' : '' ) + '/pano_' + suffix + ( !wasmSupported() ? '.jpg' : '.basis' );
				var asset = new pc.Asset( texture, 'texture', { url: texture });
				textures.push( asset.id );
				this.app.assets.add( asset );
				this.app.assets.load( asset );
				asset.ready( () => {
					assetCount++;
					if ( assetCount === 6 )
						resolve( textures );
				});
			});
		});   
    }

    #setCube = ( name, textures ) => {
		return new Promise( (resolve) => {    	
			var cube = new pc.Asset( name, 'cubemap', null, { textures: textures });
			//anisotropy: 1, magFilter: 1, minFilter: 5, rgbm: false
			//
			this.app.assets.add( cube );
			this.app.assets.load( cube );
			cube.ready( () => {
				this.assetAdd( cube );
				resolve( cube.resource );
			});
		});
    }

    isNeedUpdate( pano, change ) {
    	let findLoadedCube = this.app.assets._assets.find(o => o.name === pano );
    	let changePrefix = ( change !==null ? change.split(':') : null );
    	let isNeedUpdate = false;
    	//
		findLoadedCube._levelsEvents.forEach( ( asset, i ) => {
			if ( changePrefix && this.#pSuffix[i] === changePrefix[0] ) {
				let file = Utils.getURLpart( asset.name, 4).replace(/\.[^/.]+$/, "").split("_")[1];
				//console.log( "file___", file, changePrefix.join(''), ( file !== changePrefix.join('') ) );
				//
				isNeedUpdate = (file !== changePrefix.join(''));
			}
		});
    	return isNeedUpdate;
    }

    updateCube( pano, change ) {
    	let findLoadedCube = this.app.assets._assets.find(o => o.name === pano );
    	//
    	return new Promise( (resolve) => {
			this.#setSides( pano, change )
				.then( sides => this.#setCube( pano, sides) )
					.then( ( cube ) => {

						findLoadedCube._levelsEvents.forEach( ( texture ) => {
							this.app.assets.remove( texture );
							texture.unload();
						});
						this.app.assets.remove( findLoadedCube );
						findLoadedCube.unload();

					 	resolve( cube );
					});    	
		});
    }

	dispose() {
		this.unloadAll();
	}

  };

export { CubeMap };