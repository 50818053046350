var Model = require('../model/AssetsModel');

module.exports = Backbone.Collection.extend({
        
    model: Model,

	parse : function( res ) {
		var data = [];
		//
 		res.items.forEach(function( res, index) {
			data.push( new Model({
		    	id:  res.sys.id,
		    	file: res.fields.file.url,
		    	dimension: res.fields.file.details.image
        	}));

		});
		//
		return data;
    }
});