import protoScript from "~/js/lib/ProtoScript";

var KeyboardInput = function(){
    this.name ="keyboardInput";
};
export default protoScript( KeyboardInput );
//
KeyboardInput.prototype.initialize = function() {
    this.orbitCamera = this.entity.script.orbitCamera;
    this.app.keyboard.on(pc.EVENT_KEYDOWN, this.onKeyDown, this);
};

KeyboardInput.prototype.postInitialize = function() {
    if (this.orbitCamera) {
        this.startDistance = this.orbitCamera.distance;
        this.startYaw = this.orbitCamera.yaw;
        this.startPitch = this.orbitCamera.pitch;
        this.startPivotPosition = this.orbitCamera.pivotPoint.clone();
    }
};

// update code called every frame
KeyboardInput.prototype.update = function(dt) {};

KeyboardInput.prototype.onKeyDown = function(event) {
    if (event.event.isOverlayEvent === true)
        return;
    if (this.orbitCamera) {
        if (event.key == pc.KEY_SPACE) {
            this.orbitCamera.reset(this.startYaw, this.startPitch, this.startDistance);
            this.orbitCamera.pivotPoint = this.startPivotPosition;
        }
    }
};