//--------------- POST EFFECT DEFINITION------------------------//
pc.extend(pc, function () {
    // Constructor - Creates an instance of our post effect
    var PostEffect = function (graphicsDevice, vs, fs, entity ) {
        
        this.needsDepthBuffer = true;
        
        this.entity = entity;
        this.cubeMapFrom = null;
        this.cubeMapTo = null;
        this.scale = null;
        this.lerp = null;
        this.distance = null;
        this.distancePassed = null;
        this.transitionEnabled = !1;
        
        this.uNear = 0;
        this.uFar = 100;  
        this.fov = 60;      
            
        // this is the shader definition for our effect
        this.shader = new pc.Shader(graphicsDevice, {
            attributes: {
                aPosition: pc.SEMANTIC_POSITION
            },
            vshader: vs,
            fshader: fs
        });
    };

    // Our effect must derive from pc.PostEffect
    PostEffect = pc.inherits(PostEffect, pc.PostEffect);

    PostEffect.prototype = pc.extend(PostEffect.prototype, {
        // Every post effect must implement the render method which 
        // sets any parameters that the shader might require and 
        // also renders the effect on the screen
        render: function (inputTarget, outputTarget, rect) {
            var device = this.device;
            var scope = device.scope;

            // Set the input render target to the shader. This is the image rendered from our camera
            scope.resolve("uColorBuffer").setValue(inputTarget.colorBuffer);
            
            scope.resolve("uCubeMapFrom").setValue(this.cubeMapFrom);
            scope.resolve("uCubeMapFromDepth").setValue(this.cubeMapFromDepth);            
            scope.resolve("uCubeMapTo").setValue(this.cubeMapTo);
            scope.resolve("uCubeMapToDepth").setValue(this.cubeMapToDepth);            
            scope.resolve("_DistanceScale").setValue(this.scale);
            scope.resolve("_Lerp").setValue(this.lerp);
            scope.resolve("_Distance").setValue(this.distance);
            scope.resolve("_DistancePassed").setValue(this.distancePassed);
            scope.resolve("uTransitionEnabled").setValue(this.transitionEnabled);

            scope.resolve("uFOV").setValue(this.fov);
            var ent = this.entity.getPosition();
            scope.resolve("uCamPos").setValue( ent ); 
            
            // Draw a full screen quad on the output target. In this case the output target is the screen.
            // Drawing a full screen quad will run the shader that we defined above
            pc.drawFullscreenQuad(device, outputTarget, this.vertexBuffer, this.shader, rect);
        }
    });

    return {
        PostEffect: PostEffect
    };
}());