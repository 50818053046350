var template = require('~/js/template/layout/popupTemplate.html'),
    App = require("~/js/App"),
    Utils = require('~/js/Utils');

module.exports = Backbone.View.extend({ 

    id: 'popup',

    className: 'popup-overlay',

    events: {
        "click .close-icon":  "onClose",
        "click button.no":  "onButtonNo",
        "click button.yes":  "onButtonYes",
        "click .dropdown ul li a":  "onDropDownSelect"
    },   

    initialize: function () {
    },

    render: function() {
        return this;
    },


    show: function( data, callback ) {
        this.el.innerHTML = template( { data: data, translate: App.translateCollection, Utils } );
        //disbale confirm buttom when dropdown is
        if ( data.select && data.select.length > 1 ) {
            Utils.addClass( this.el.querySelector( 'button.yes' ), 'disabled' );
        }
        //
        this.domInput = this.el.querySelector( 'input' );        
        Utils.addClass( this.el, 'show' );

        this.callback = callback;
    },

    hide: function() {
        Utils.removeClass( this.el, 'show' );

    },

    onDropDownSelect: function( evt ) {
        //evt.preventDefault();
        var value = evt.target.text;
        var code = evt.target.id;
        var label = evt.target.parentNode.parentNode.parentNode.querySelector('a'); //dropdown label
        //
        if ( label )
            label.text = value;
        //
        Utils.removeClass( this.el.querySelector( 'button.yes' ), 'disabled' );
        this.dropdownId = code;
    },

    onClose: function( evt ) {
        this.hide();
        this.callback(null);        
    },

    onButtonNo: function( evt ) {
        this.hide();
        this.callback(null);
    },

    onButtonYes: function( evt ) {
        this.hide();        
        this.callback( this.domInput ? this.domInput.value : this.dropdownId ? this.dropdownId : {} );
    },

    dispose: function() {
        this.unbind();
        this.$el.empty();
        this.$el.remove();
        this.remove();
    }

});
