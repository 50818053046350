import protoScript from "~/js/lib/ProtoScript";

var FlowInfo = function(){
    this.name = 'flowInfo';
    //
    this.attributes = {
        'graphNode': { type: {} },
        'elementName': { type: "string", default:"" },
        'show': { type: "string", default:"" },
        'animComponent': { type: {} }
    };
};

FlowInfo.prototype.initialize = function() {
	this.camera = this.app.root.findByName('camera');
 	this.alpha = -1.0;
};
FlowInfo.prototype.update = function( dt ) {
	var element = document.getElementById( this.elementName );
	if ( element ) {
		this.alpha += ( Math.min( 1.0, this.graphNode.forward.dot( this.camera.forward ) * 3 ) - this.alpha ) * 0.1;
		var point = this.camera.camera.worldToScreen( this.graphNode.getPosition() );
		point.x = Math.round(point.x)-32; //32px = 2rem padding
		point.y = Math.round(point.y)-32;
        if ( this.alpha < 0) {
            element.style.pointerEvents = "none";
        } else {
            element.style.pointerEvents = "auto";
        }
		//
        element.style.top = point.y + "px"; //(this.popupPosition.y - this.popup.offsetHeight - 70) + "px";
        element.style.left = point.x + "px";

        if ( !this.animComponent ) {
            element.style.opacity = this.alpha;
        } else if ( this.animComponent.curClip === this.show || this.show === undefined )
        	element.style.opacity = this.alpha;
        else
        	element.style.opacity = 0;
        //
    }	

	
};

export default protoScript( FlowInfo );
//