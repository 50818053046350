module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul>\r\n    <li><a href="/"><span>'+
((__t=( translate.getText( 'bread_home', 'shortText' ) ))==null?'':__t)+
'</span></a></li>\r\n    ';
 for ( var i = collection.length - 1; i >=1; i-- ) { 
__p+='\r\n    	<li><a href="'+
((__t=( collection.at(i).get('slug') ))==null?'':__t)+
'"><span>'+
((__t=( collection.at(i).get('title').replace("<br>"," ") ))==null?'':__t)+
'</span></a></li>\r\n    ';
 } 
__p+='\r\n    <li><span>'+
((__t=( collection.at(0).get('title').replace("<br>"," ") ))==null?'':__t)+
'</span></li>    \r\n</ul>\r\n  ';
}
return __p;
};
