module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!-- <div id="title">'+
((__t=( data.get('title') ))==null?'':__t)+
'</div> /-->\r\n\r\n<div class="directions">\r\n';
 _.each( data.get('directions'), function( direction, index ) { 
__p+='\r\n		<div class="direction" id="'+
((__t=( direction.id ))==null?'':__t)+
'">'+
((__t=( direction.title ))==null?'':__t)+
'</div>\r\n';
 }); 
__p+='\r\n</div>\r\n\r\n<ul class="product flow-info">\r\n';
 _.each( data.get('productsInPano'), function( product, index ) {
	if ( product.interactive ) { 
__p+='  \r\n	<li id="'+
((__t=( product.id ))==null?'':__t)+
'">\r\n		<span>'+
((__t=( product.title ))==null?'':__t)+
'</span>\r\n	</li> \r\n';
 }
}); 
__p+='\r\n</ul>\r\n\r\n';
 if ( data.get('pppProducts') ) { 
__p+='\r\n	<ul class="pppProduct flow-info">\r\n	';
 _.each( data.get('pppProducts'), function( flowInfo, index ) { 
__p+='   \r\n		<li id="'+
((__t=( flowInfo.id ))==null?'':__t)+
'">\r\n		<span>'+
((__t=( flowInfo.id ))==null?'':__t)+
'</span>\r\n		<div class="popup top">\r\n			<h2><a href="'+
((__t=( flowInfo.url ))==null?'':__t)+
'" ';
 if (flowInfo.url){ 
__p+=' class="link"';
 } 
__p+='>'+
((__t=( flowInfo.title ))==null?'':__t)+
'</a></h2>\r\n			<p></p>\r\n			</a>\r\n			<a href="#0" class="close-popup">Close</a>\r\n		</div>\r\n		</li> \r\n	';
 }); 
__p+='\r\n	</ul>\r\n';
 } 
__p+='';
}
return __p;
};
