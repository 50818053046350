  var currMethodName = '';
  var currArgsLength = 0;  
  var bindRoutes = function(Router) {
    for (var url in this.routes) {
      // Using default Backbone.js route method.
      // Same URLs from different controllers are not allowed.
      // Last controller with same URL will be used.
      Router.route(url, url, _.bind(onRoute, this, url));
    }
  },
  onRoute = function() {
    var self = this,
        args = _.toArray(arguments),
        url = args[0],
        methodName = this.routes[url],
        params = args.slice(1),
        triggerRouteAndAfterRoute = function() {
          
          if ( currMethodName !== methodName ) {
              // Call route method with routing parameters like :id, *path etc
              self[methodName].apply(self, params);
          } else {
              self[methodName + 'Update'].apply(self, params);
          }
          //
          if ( currArgsLength === 0 ) 
              self['directionStart'].apply(self); //console.log('smerem nikam');    
          else if ( args.length === currArgsLength )
              self['directionSame'].apply(self); //console.log('smerem rovne');
          else if ( args.length > currArgsLength )
              self['directionUp'].apply(self); //console.log('smerem nahoru');          
          else
              self['directionDown'].apply(self); //console.log('smerem dolu'); 
          //
          currMethodName = methodName;
          currArgsLength = args.length;

          // Call onAfterRoute after route
          if ( _.isFunction(self.onAfterRoute)) {
            self.onAfterRoute.apply(self, args);
          }
        },
        beforeRouteResult, isPromiseObj;

    // Call remove if router goes to another controller
    if (cachedController && cachedController !== this &&
      typeof cachedController.remove === 'function') {

      cachedController.remove.apply(cachedController);
    }
    cachedController = this;

    // Call onBeforeRoute before route
    if ( _.isFunction(this.onBeforeRoute) ) {
      beforeRouteResult = this.onBeforeRoute.apply(this, args);
    }

    if (beforeRouteResult === false || beforeRouteResult === null) return this;
    isPromiseObj = beforeRouteResult && beforeRouteResult.done && _.isFunction(beforeRouteResult.done);

    if (isPromiseObj) {
      beforeRouteResult.done(triggerRouteAndAfterRoute);
    } else {
      triggerRouteAndAfterRoute();
    }
  },
  cachedRouter,
  cachedController;

  Backbone.Controller = function(options){
    this.options = options || {};
    if (_.isFunction(this.initialize)){
      this.initialize(this.options);
    }
    if (this.options.router === true) {
      // Save/get to/from closure router instance for binding routes
      cachedRouter = cachedRouter || new Backbone.Router();
      this.options.router = cachedRouter;
    }
    if (this.options.router) {
      cachedRouter = this.options.router;
      bindRoutes.call(this, this.options.router);
    }
  };

  // Method uses cached Backbone Router and allows navigate to another route
  Backbone.Controller.prototype.navigate = function() {
    var params = _.toArray(arguments).slice(0);
    cachedRouter.navigate.apply(this, params);
  };
  
  Backbone.Controller.extend = Backbone.Router.extend;
  
  // Supporting default Backbone events like on, off, trigger, listenTo etc
  // Provides remove method which can be called on controller removal.
  _.extend(Backbone.Controller.prototype, Backbone.Events, {
    remove: function() {
      this.stopListening();
    }
  });