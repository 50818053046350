var template = require('~/js/template/layout/sideMenuTemplate.html'),
    SideMenuCollection = require('~/js/collection/SideMenuCollection'),
    Contentful = require('~/js/Contentful'),
    App = require("~/js/App"),
    Utils = require('~/js/Utils');

module.exports = Backbone.View.extend({

      tagName: 'aside',

      className: 'sidemenu',

      events: {
          "click ul.accordion li a":            "onAccordionClick",
          "click ul.language li a":            "onLanguageClick",
          "click .cookie-set":                 "onCookieClick"
      },    

      initialize: function() {
          this.collection = new SideMenuCollection();     
          this.getData();
          //
          Backbone.history.on('route', this.historyChange, this );   
          App.eventBus.on('languageChange', this.onLanguageChange, this);
      },

      render: function() {
        //I need convert flat array collection into tree array, nice ES6 method
        const nest = (items, id = null ) =>
          items
            .filter(item => item.get('parent') === id)
            .map(item => ({ 
              id: item.get('id'),              
              title: item.get('title'),
              slug: item.get('slug'),
              reference: item.get('reference'),              
              picture: item.get('picture'),
              childrens: nest(items, item.get('id') )
        }));
        this.el.innerHTML = template( { Utils, data: nest( this.collection.models ), lang: App.LANGUAGE, dataLng: App.languageCollection.models, translate: App.translateCollection } );
        //   
        return this;
      },

      getData: function() {
          this.collection.url = Contentful.getEntries( 'mainMenu' );
          this.collection.fetch({ reset:true }).done( () => {
              this.render();
          });

      },
      historyChange: function( event ) {
          this.getData();
      },
      onLanguageChange: function() {
          this.getData();
      },

      onAccordionClick:function( evt ){
          evt.preventDefault();
          this.unselectAll();
          //
          Utils.addClass( evt.currentTarget, 'selected' );
          Backbone.history.navigate( evt.currentTarget.pathname, true );
      },

      onCookieClick:function( evt ){
          App.cookie.show();
      },

      unselectAll: function() {
          this.el.querySelectorAll('a').forEach( ( item, index) => {
              Utils.removeClass( item, 'selected' );
          });
      },

      onLanguageClick: function( evt ){
          evt.preventDefault();
          //
          var target = evt.currentTarget.parentNode;
          App.LANGUAGE = (target.getAttribute('value'));
          //update translate collection with new language
          App.translateCollection.url = Contentful.getEntries( 'translate' );
          App.translateCollection.fetch().done(() => {
              App.eventBus.trigger('languageChange');
          });
      }
});