var BasicController = require('./BasicController'),
	SolutionView = require('../view/content/SolutionView'),
 	App = require("~/js/App");

module.exports = BasicController.extend({
	routes: {
		[App.HOME_DIR + ':id'] :'setSolution',
	},

	initialize: function() {
	},

	setSolution: function( id ) {
		this.view = new SolutionView( id );
		document.body.querySelector('main').appendChild( this.view.el );   
	},

	//smerem na 3d po dokonceni transitionu definitivne uklid
    onAnimationEndOut: function() {
    	this.view.dispose();
    },

	remove: function() {
		App.dispose.view = this.view;
	}
});